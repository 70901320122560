import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Bars from './BarLoading';
import './Modal.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  formControl2: {
    minWidth: 120,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});


class UploadPhotoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productAdd:window.$productAdd,
            open: false,
            isAdd:true,
            file:null,
            name:'',
            code:'',
            brandCategory:'',
            brand:'',
            desc:'',
            category:'',
            brands:[],
            show:true,
            setOpen:false,
            brandFilter:0,
            labelWidth: 2,
        };
      }
      changedRole = ev => {
        //console.log(ev.target.value)
        var datas=this.state.brands
        this.setState({ 
          brandFilter: ev.target.value,
        })
        if(ev.target.value==0){
          this.setState({
            catItem:this.state.catItemAll
          })
          return;
        }
        for(var i=0;i<datas.length;i++){
          var id=datas[i].id;
          //console.log("ada")
          if(id==ev.target.value){
            
            this.setState({
              catItem:datas[i].cats
            })
            break
          }
        }
        
    }
      handleClose = () => {
        this.setState({
          setOpen:false
        });
      };
      handleOpen = () => {
        this.setState({
          setOpen:true
        });
      }
      setName = (e) => {
        this.setState({
            name: e.target.value
        })
     }
     setCode = (e) => {
        this.setState({
            code: e.target.value
        })
     }
     setBrandCategory = (e) => {
        this.setState({
            brandCategory: e.target.value
        })
     }
     setCategory = (e) => {
        this.setState({
            category: e.target.value
        })
     }
     setId = (e) => {
        this.setState({
            brand: e.target.value
        })
     }
     setDesc = (e) => {
        this.setState({
            desc: e.target.value
        })
     }
     handleChange = event => {
      this.setState({
        setAge:event.target.value
      });
    };
handleClick(event){
let keyword4 = parseInt(this.state.brand) ;
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"t":"token","brand_id":keyword4,"brand_cat":this.state.brandCategory,"code":this.state.code,"name":this.state.name,"category":this.state.category,"description":this.state.desc});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(window.$productAdd, requestOptions)
  .then(response => response.json())
  .then(result =>
    {
      if(result.msg=="Success"){
        this.setState({
          isAdd:true,
          show:false
        })
      }else{
        this.setState({
          isAdd:true
        })
      }
      }
    )
  .catch(error => console.log('error', error));

     }
    
     componentDidMount(){
    this.laodBrand(); 
    }
  laodBrand(){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"t":"token"});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(window.$brandIndex, requestOptions)
      .then(response => response.json())
      .then(responseJson => {
        //console.log(responseJson.result)
      this.setState({
        brands:responseJson.result
        
      });
      })
      .catch(error => console.log('error', error));
  }
  render() {
    const { classes } = this.props;
    const {isAdd,show,brands} = this.state;
    return (
      <React.Fragment>
        <Dialog
        maxWidth="md"
          open={this.props.show}
          onClose={this.props.close}
          
          
        >
          <DialogTitle id="draggable-dialog-title">Add Product</DialogTitle>
          <Divider/>
          {isAdd ? (
            <DialogContent>
            <FormControl variant="outlined" fullWidth className={classes.textField} margin="normal">
                <InputLabel ref={ref => {this.InputLabelRef = ref;}} id="demo-controlled-open-select-label">Brand</InputLabel>
                <Select native
                  labelId="demo-controlled-open-select-label"
                  open={this.state.setOpen} onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  value={this.state.setAge}
                  onChange={this.props.brand}
                  input={
                    <OutlinedInput
                      name="Brand"
                      labelWidth={60}
                      id="demo-controlled-open-select-label"
                    />
                  }
                >
                <option value="" ></option>
                {brands.map(brand => (
                    <option value={brand.id}>{brand.name}</option>
                ))}
                </Select>
            </FormControl>
            <TextField label="Brand Category" className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {this.props.brandCat}/>
            <TextField label="Category" className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {this.props.category}/>
            <TextField label="Name" className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {this.props.name}/>
            <TextField label="Code" className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {this.props.code}/>
            <TextField label="Description" className={classes.textField} type="text" multiline fullWidth margin="normal" variant="outlined" onChange = {this.props.desc}/>
            <TextField label="SEO" className={classes.textField} type="text" multiline fullWidth margin="normal" variant="outlined" onChange = {this.props.seo}/>
            </DialogContent>
          ):(
            <Bars/>
          )}
          
          <Divider/>
          <DialogActions>
            <Button onClick={this.props.close} class="btn btn-secondary">
              Close
            </Button>
            <Button onClick={this.props.submit} class="btn btn-danger">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

UploadPhotoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadPhotoDialog);