import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Bars from './BarLoading';
import './Modal.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class UploadPhotoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brandAdd:window.$brandAdd,
            open: false,
            file:null,
            name:'',
            code:'',
            brandCategory:'',
            brand:'',
            desc:'',
            isAdd:true,
            show:this.props.show
        };
      }

      setName = (e) => {
        this.setState({
            name: e.target.value
        })
     }
     setCode = (e) => {
        this.setState({
            code: e.target.value
        })
     }
     setBrandCategory = (e) => {
        this.setState({
            brandCategory: e.target.value
        })
     }
     setCategory = (e) => {
        this.setState({
            category: e.target.value
        })
     }
     setId = (e) => {
        this.setState({
            brand: e.target.value
        })
     }
     setDesc = (e) => {
        this.setState({
            desc: e.target.value
        })
     }
    
     handleClick(event){
      this.setState({
        isAdd:false
      })
      const {brandAdd}=this.state
        let keyword1 = this.state.name;
        let keyword2 = this.state.code;
        let keyword3 = this.state.category;
        let keyword4 = parseInt(this.state.brand) ;
        let keyword5 = this.state.desc;
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"t":"token","name":this.state.name,"description":this.state.desc});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(window.$brandAdd, requestOptions)
.then(response => response.json())
.then(result =>
  {
    if(result.msg=="Success"){
      this.setState({
        isAdd:true,
        show:false
      })
    }else{
      this.setState({
        isAdd:true
      })
    }
    }
  )
  .catch(error => console.log('error', error));

     }
     
  render() {
    const { classes } = this.props;
    const {isAdd} = this.state;
    return (
      <React.Fragment>
        <Dialog
        maxWidth="xs"
          open={this.props.show}
          onClose={this.props.close}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title">{this.props.title}</DialogTitle>
          <Divider/>
          {isAdd ? (
            <DialogContent>
            <TextField label="Old password" className={classes.textField} type="password" fullWidth margin="normal" variant="outlined" onChange = {this.props.oldPass}/>
            <TextField label="New Password" className={classes.textField} type="password" fullWidth margin="normal" variant="outlined" onChange = {this.props.newPass}/>
            </DialogContent>
          ):(
            <Bars/>
          )}
          <Divider/>
            <DialogActions>
            <Button onClick={this.props.close} class="btn btn-secondary">
              Close
            </Button>
            <Button onClick={this.props.submit} class="btn btn-danger">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

UploadPhotoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadPhotoDialog);