import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Bars from './BarLoading';
import './Modal.css';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    dense: {
      marginTop: 16,
    },
    menu: {
      width: 200,
    },
  });

class UploadPhotoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productUpdate:window.$productUpdate,
            open: false,
            isAdd:true,
            file:null,
            brandName:this.props.brandNm,
            brands:[],
            setOpen:false,
        };
      }
      handleClose = () => {
        this.setState({
          setOpen:false
        });
      };
      handleOpen = () => {
        this.setState({
          setOpen:true
        });
      }
      handleChange = event => {
        this.setState({
          setAge:event.target.value
        });
      };
      setName = (e) => {
        this.setState({
            name: e.target.value
        })
     }
     setCode = (e) => {
        this.setState({
            code: e.target.value
        })
     }
     setBrandCategory = (e) => {
        this.setState({
            brandCategory: e.target.value
        })
     }
     setCategory = (e) => {
        this.setState({
            category: e.target.value
        })
     }
     setId = (e) => {
        this.setState({
            brand: e.target.value
        })
     }
     setDesc = (e) => {
        this.setState({
            desc: e.target.value
        })
     }
     componentDidMount(){
      
      this.laodBrand(); 
      }
      laodBrand(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({"t":"token"});
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch(window.$brandIndex, requestOptions)
          .then(response => response.json())
          .then(responseJson => {
            //console.log(responseJson.result)
          this.setState({
            brands:responseJson.result
            
          });
          })
          .catch(error => console.log('error', error));
      }
     
  render() {
    const { classes } = this.props;
    const {isAdd,brands} = this.state;
    return (
      <React.Fragment>
        <Dialog
        maxWidth="md"
          open={this.props.show}
          onClose={this.props.close}
          
          
        >
          <DialogTitle id="draggable-dialog-title">Edit Product</DialogTitle>
          <Divider/>
          {isAdd ? (
              <div>
              {this.props.done?(
                <DialogContent>
                <TextField label="Name" value={this.props.name} className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {this.props.nameChange}/>
                <TextField label="Code" value={this.props.code} className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {this.props.codeChange}/>
                <TextField label="Brand Category" value={this.props.brandCat} className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {this.props.brandCatChange}/>
                <TextField label="Category" value={this.props.category} className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {this.props.categoryChange}/>
                <FormControl variant="outlined" fullWidth className={classes.textField} margin="normal">
                <InputLabel ref={ref => {this.InputLabelRef = ref;}} id="demo-controlled-open-select-label">Brand</InputLabel>
                <Select native
                  labelId="demo-controlled-open-select-label"
                  open={this.state.setOpen} onClose={this.handleClose}
                  onOpen={this.handleOpen}
                  value={this.state.setAge}
                  onChange={this.props.brandChange}
                  input={
                    <OutlinedInput
                      name="Brand"
                      labelWidth={60}
                      id="demo-controlled-open-select-label"
                    />
                  }
                >
                <option value={this.props.brandId} >{this.props.brandNm}</option>
                {brands.map(brand => (
                    <option value={brand.id}>{brand.name}</option>
                ))}
                </Select>
              </FormControl>
                <TextField label="Description" value={this.props.desc} className={classes.textField} type="text" multiline fullWidth margin="normal" variant="outlined" onChange = {this.props.descChange}/>
                <TextField label="SEO" value={this.props.seo} className={classes.textField} type="text" multiline fullWidth margin="normal" variant="outlined" onChange = {this.props.seoChange}/>
                </DialogContent>
              ):(
                  <Bars/>
              )}
            
            </div>
          ):(
            <Bars/>
          )}
          
          <Divider/>
          <DialogActions>
            <Button onClick={this.props.close} class="btn btn-secondary">
              Close
            </Button>
            <Button onClick={this.props.submit} class="btn btn-danger">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

UploadPhotoDialog.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(UploadPhotoDialog);