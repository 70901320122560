import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import './Modal.css';

function PaperComponent(props) {
  return (
    <Draggable>
      <Paper {...props} />
    </Draggable>
  );
}

class DraggableDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isOpen: false
        };
      }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.show}
          onClose={this.props.close}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title">Photo</DialogTitle>
          <Divider/>
          <DialogContent>
          <TableContainer>
          <Table aria-label="simple table" className="table table-striped" style={{minWidth:650}}>
          <TableBody>
          <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>{this.props.id}</TableCell>
          </TableRow>
          <TableRow>
          <TableCell>Product Id</TableCell>
          <TableCell>{this.props.prodId}</TableCell>
          </TableRow>
          <TableRow>
          <TableCell>Create At</TableCell>
          <TableCell>{this.props.createAt}</TableCell>
          </TableRow>
          <TableRow>
          <TableCell>Update At</TableCell>
          <TableCell>{this.props.updateAt}</TableCell>
          </TableRow>
          <TableRow>
          <TableCell>Image Url</TableCell>
          <TableCell>{this.props.url}</TableCell>
          </TableRow>
          <TableRow>
          <TableCell>Description</TableCell>
          <TableCell>{this.props.desc}</TableCell>
          </TableRow>
          
          </TableBody>
          </Table>
      </TableContainer>
          </DialogContent>
          <Divider/>
          <DialogActions>
          <Button onClick={this.props.delete} class="btn btn-danger">
            Delete
          </Button>
            <Button onClick={this.props.close} color="btn btn-secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DraggableDialog;