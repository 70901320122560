import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '../components/Drawer';
import ModalProd from '../components/DialogProductPhotoDetail';
import ModalDelProd from '../components/DialogDeleteItem';
import ModalAdd from '../components/DialogUploadPhoto';

export default class BrandContainer extends Component {
	state = {
        idText:"eid",
        productUploadImage:window.$productUploadImage,
        productIndexImage:window.$productIndexImage,
        productFindById:window.$productFindById,
        productDelImage:window.$productDelImage,
        url:'', imageUrl:'', desc:'', ids:'', prod_id:'', restImageUrl:'',brands:[],isAdd:true,
        products: [],
        modalItem:{},
        page:0,
        labelWidth: 0,
        avaliable:true, show: false, showAdd:false,showEdit:false,showDelete:false,file:null,
        rowsPerPage:25, brandFilter:0, catFilter:null, searchField:'', isDone: false, isLoading: true, 
		color: 'rgba(52, 52, 52, 0.8)'
	  }
	  listenScrollEvent = e => {
		if (window.scrollY > 400) {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		} else {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		}
	  }
	
	
	  componentDidMount(){
        window.addEventListener('scroll', this.listenScrollEvent)
      this.loadItem();
      }
    handleChangePage = (event, newPage) => {
        this.setState({
            page:newPage
        })
    };
    handleRefresh(){
      this.setState({
        products:[],
        isLoading:true
      })
      this.loadItem();
    }

    handleFileChange = e => {
      const reader = new FileReader();
      this.setState({
        file: e.target.files[0],
        url:e.target.files[0].name,
        imageUrl:URL.createObjectURL(e.target.files[0])
      })
      reader.onloadend = () => {
        this.setState({
          imageUrl: reader.result
        });
        
      }
    }
    handleDescChange = e => {
      this.setState({
        desc:e.target.value
      })
      
    }
    handleIdChange = e => {
      this.setState({
        prod_id:e.target.value
      })
      
    }
    hadleSubmit=nr=>()=> {
      if(this.state.prod_id!==''){
        if(this.state.file!== null){
          if(this.state.desc!==''){
            this.setState({
              showAdd : false,
              isAdd:false
          });
          this.uploadImage();
          }else{
            alert('please input description')
          }
        }else{
          alert('please input select image')
        }
      }else{
        alert('please input id product ')
      }
      
    }
  
  uploadImage(){
      const{file,url,prod_id,desc}= this.state;

      this.setState({
        isAdd:false
      })

      var formdata = new FormData();
  formdata.append("t", "token");
  formdata.append("eid", prod_id);
  formdata.append("file[]", file, url);
  formdata.append("file_desc[]", desc)
  
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(window.$productUploadImage, requestOptions)
    .then(response => response.json())
    .then(result =>
      {
        if(result.msg==="Success"){
          this.setState({
            url:'', imageUrl:'', desc:'', ids:'',file:null,
            isAdd:true
          })
          this.handleRefresh()
        }else{
          this.setState({
            isAdd:true
          })
        }
        }
      )
    .catch(error => console.log('error', error));
    }

    editPhoto(id){

    }


    loadItem(){
        const{productIndexImage}=this.state;
        //console.log(keyword)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({"t":"token"});
        
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        
        fetch(productIndexImage, requestOptions)
        .then(response => response.json())
        .then(responseJson => {
          //console.log(responseJson.result)
        if(responseJson.result.length===0){
          //console.log("true")
            this.setState({
                avaliable:true,
                isLoading:false
                });
        }else{
            this.setState({
                avaliable:false,
                products:responseJson.result,
                isLoading:false
            });
        }
        this.setState({
        isLoading:false
        });
        })
        .catch(error => console.log('error', error));
    }


    closeModal=nr=>()=> {
      this.setState({
          show : false
      });
    }

    closeModalDelete=nr=>()=> {
      this.setState({
        showDelete : false
      });
    }
    handleModalDelte(id){
      this.setState({
        showDelete: true,
        show:false
      });
      this.loadModal(id);
    }
    closeModalDeleteOk(id){
      this.setState({
        showDelete: false
      });
      this.deleteItem(id);
    }
    closeModalEdit=nr=>()=> {
      this.setState({
        showEdit : false
      });
    }
    handleModalEdit(id){
      this.setState({
        showEdit: true,
        isDone:false,
        show:false
      });
      this.loadModal(id);
    }

    handleModal(id){
  
      this.setState({
        show: true,
        isDone:false
       
      });
      this.loadModal(id);
    }

    closeModalAdd=nr=>()=> {
      this.setState({
          showAdd : false
      });
    }
    handleModalAdd(){
      this.setState({
        showAdd: true,
      });
    }
    
    deleteItem(id){
      const{productDelImage}=this.state;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({"t":"token","id":id});

      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(productDelImage, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status===true){
        this.handleRefresh()
      }
    })
    .catch(error => console.log('error', error));
    }
    
    loadModal(id){
      var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"t":"token","id":id});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(window.$productImageFindById, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          modalItem:result.result[0],
          ids:result.result[0].id,
          prod_id:result.result[0].product_id,
          restImageUrl:result.result[0].url,
          desc:result.result[0].description,
          isDone:true
         
        })
        })
      .catch(error => console.log('error', error));
    }

    loadImage(images){
      if(images[0]!==null){
        return images[0].url;
      }
      return "No Images";
    }


	render() {
        const { avaliable,page,rowsPerPage,products,isDone,modalItem,isLoading} = this.state;
		return(
            <div>
            <Backdrop open={isLoading}>
            <CircularProgress color="inherit" />
            </Backdrop>
            <Drawer halaman={this.props.halaman} pindahHalaman={this.props.pindahHalaman}>
            <div>
            <h3>Product photos</h3>
            </div>
            <Paper elevation={3}>
            {isLoading?(
              <div></div>
            ):(
              <div>
              {avaliable ? (
              <div>
              <div className="container d-flex justify-content-center">
              <h2>Item not Avaliable</h2>
              </div>
              
              </div>
              ):(
                <div>
                <div >
                <Button variant="contained" color="secondary" style={{marginTop:10,marginBottom:10,marginLeft:10}}onClick={()=>this.handleRefresh()}>
                Refresh
                </Button>
                </div>
    
                <TableContainer>
                <Table aria-label="simple table" className="table table-striped" style={{minWidth:650}}>
                <TableHead>
                <TableRow>
                <TableCell style={{fontWeight:'bold'}}>product id</TableCell>
                <TableCell style={{fontWeight:'bold'}}>url</TableCell>
                </TableRow>
                </TableHead>
               <TableBody>
               {products.slice(page*rowsPerPage,page*rowsPerPage+rowsPerPage)
                .map((items)=>(
                    <TableRow>
                    <TableCell><a href="#" class="text-primary" onClick={()=>this.handleModal(items.id)}>{items.product_id}</a></TableCell>
                    <TableCell><img class="img-fluid "src={window.$ImageBaseUrl+"/"+items.url} style={{height:80, maxWidth:200}}/></TableCell>
                    </TableRow>
                ))}
               </TableBody> 
                </Table>
                </TableContainer>
                <TablePagination
                rowsPerPageOptions={false}
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                />
                </div>
              )}
              </div>
            )}
            </Paper>
            <ModalAdd
            show={this.state.showAdd}
            close={this.closeModalAdd()}
            imageUrl={this.state.imageUrl}
            idChange={e=>this.handleIdChange(e)}
            fileChange={(e)=>this.handleFileChange(e)}
            descChange={e=>this.handleDescChange(e)}
            submit={this.hadleSubmit()}
            />
            <ModalDelProd
            show={this.state.showDelete}
            close={this.closeModalDelete()}
            ok={()=>this.closeModalDeleteOk(modalItem.id)}
            text={"product photo"}
            />
            <ModalProd
              show={this.state.show}
              close={this.closeModal()}
              delete={()=>this.handleModalDelte(modalItem.id)}
              done={isDone}
              id={modalItem.id}
              prodId={modalItem.product_id}
              createAt={modalItem.created_at}
              updateAt={modalItem.updated_at}
              desc={modalItem.description}
              url={modalItem.url}
              />
            </Drawer>
		  </div>
		)
	}
}
