import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Drawer from '../components/Drawer';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class DraggableDialog extends React.Component {
  state = {
    open: false,
    name:'',
    code:'',
    brandCategory:'',
    brand:'',
    desc:'',
  };

  setName = (e) => {
    this.setState({
        name: e.target.value
    })
 }
 setCode = (e) => {
    this.setState({
        code: e.target.value
    })
 }
 setBrandCategory = (e) => {
    this.setState({
        brandCategory: e.target.value
    })
 }
 setCategory = (e) => {
    this.setState({
        category: e.target.value
    })
 }
 setId = (e) => {
    this.setState({
        brand: e.target.value
    })
 }
 setDesc = (e) => {
    this.setState({
        desc: e.target.value
    })
  }

  uploadImage(){
    const{file,url,ids,desc}= this.state;
    var formdata = new FormData();
formdata.append("t", "token");
formdata.append("eid", ids);
formdata.append("file[]", file, url);
formdata.append("file_desc[]", desc)

var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
};

fetch(window.$brandUploadImage, requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));
  }

  render() {
    const { classes } = this.props;
    let imgPreview;
        if (this.state.url) {
            imgPreview = <img src={this.state.url} alt='' />;
        }
    return (
      <div>
       <Drawer halaman={this.props.halaman} pindahHalaman={this.props.pindahHalaman}>
        <div>
        <TextField label="Name" className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {e => this.setName(e)}/>
        <TextField label="Code" className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {e => this.setCode(e)}/>
        <TextField label="Brand Category" className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {e => this.setBrandCategory(e)}/>
        <TextField label="Category" className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {e => this.setCategory(e)}/>
        <TextField label="BrandId" className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {e => this.setId(e)}/>
        <TextField label="Description" className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {e => this.setDesc(e)}/>
        </div>
        </Drawer>
      </div>
    );
  }
}

DraggableDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DraggableDialog);