import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Bars from './BarLoading';
import './Modal.css';

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    dense: {
      marginTop: 16,
    },
    menu: {
      width: 200,
    },
  });

class UploadPhotoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brandUpdate:window.$brandUpdate,
            open: false,
            isAdd:true,
            file:null,
            name:this.props.name,
            desc:this.props.desc,
            show:this.props.show
        };
      }

      setName = (e) => {
        this.setState({
            name: e.target.value
        })
     }
     
     setDesc = (e) => {
        this.setState({
            desc: e.target.value
        })
     }
    
     handleClick(event){
       this.setState({
         isAdd:false
       })
       const {brandUpdate}=this.state
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"t":"token","id":this.props.id,"Name":this.state.name,"Description":this.state.desc});
var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(window.$brandUpdate, requestOptions)
  .then(response => response.json())
  .then(result =>
    {
      if(result.msg=="Success"){
        this.setState({
          isAdd:true,
          show:false
        })
      }else{
        this.setState({
          isAdd:true
        })
      }
      }
    )
  .catch(error => console.log('error', error));

     }
  render() {
    const { classes } = this.props;
    const {isAdd,name,code,brandCategory,brand,category,desc} = this.state;
    return (
      <React.Fragment>
        <Dialog
        maxWidth="md"
          open={this.props.show}
          onClose={this.props.close}
          
          
        >
          <DialogTitle id="draggable-dialog-title">{this.props.title}</DialogTitle>
          <Divider/>
          {isAdd ? (
              <div>
              {this.props.done?(
                <DialogContent>
                <TextField label="Name" value={this.props.name} className={classes.textField} type="text" fullWidth margin="normal" variant="outlined" onChange = {this.props.nameChange}/>
                <TextField label="Description" value={this.props.desc} className={classes.textField} type="text" multiline fullWidth margin="normal" variant="outlined" onChange = {this.props.descChange}/>
                </DialogContent>
              ):(
                  <Bars/>
              )}
            
            </div>
          ):(
            <Bars/>
          )}
          
          <Divider/>
          <DialogActions>
            <Button onClick={this.props.close} class="btn btn-secondary">
              Close
            </Button>
            <Button onClick={this.props.submit} class="btn btn-danger">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

UploadPhotoDialog.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(UploadPhotoDialog);