import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Bars from './BarLoading';
import './Modal.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class UploadPhotoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brandAdd:window.$brandAdd,
            open: false,
            file:null,
            name:'',
            code:'',
            brandCategory:'',
            brand:'',
            desc:'',
            isAdd:true,
            show:this.props.show
        };
      }

      setName = (e) => {
        this.setState({
            name: e.target.value
        })
     }
     setCode = (e) => {
        this.setState({
            code: e.target.value
        })
     }
     setBrandCategory = (e) => {
        this.setState({
            brandCategory: e.target.value
        })
     }
     setCategory = (e) => {
        this.setState({
            category: e.target.value
        })
     }
     setId = (e) => {
        this.setState({
            brand: e.target.value
        })
     }
     setDesc = (e) => {
        this.setState({
            desc: e.target.value
        })
     }
    
     handleClick(event){
      this.setState({
        isAdd:false
      })
      const {brandAdd}=this.state
        let keyword1 = this.state.name;
        let keyword2 = this.state.code;
        let keyword3 = this.state.category;
        let keyword4 = parseInt(this.state.brand) ;
        let keyword5 = this.state.desc;
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"t":"token","name":this.state.name,"description":this.state.desc});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(window.$brandAdd, requestOptions)
.then(response => response.json())
.then(result =>
  {
    if(result.msg=="Success"){
      this.setState({
        isAdd:true,
        show:false
      })
    }else{
      this.setState({
        isAdd:true
      })
    }
    }
  )
  .catch(error => console.log('error', error));

     }
     loadImageItem(images){
        if(images!==null){
          var urls=images.split('/')
        var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+urls[3]+"/"+"L"+"/"+urls[4]
          return img
        }
        return require('../assets/placeholder.jpg');
      }
  render() {
    const { classes } = this.props;
    const {isAdd} = this.state;
    return (
      <React.Fragment>
      <Dialog
        open={this.props.show}
        onClose={this.props.close}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">Image</DialogTitle>
        <Divider/>
        {this.props.done?(
          <DialogContent>
          <div className="product-grid row">
                {this.props.images.map((info)=>(
                <div className="col-sm-6">
                <Paper style={{height:270,width:200,backgroundColor:'white'}}>
                  <div className="card wow fadeInUp mb-4" style={{height:270,width:200,backgroundColor:'white'}} >
                  
                  <div className="d-flex justify-content-end">
                  <a href="#" onClick={e=>this.props.delete.handleDeleteImage(info.id)}><img src={require('../assets/ic_delete.png')} height={20} style={{marginRight:5,marginTop:5}}/></a>
                  </div>
                    <div className="mt-2 d-flex justify-content-center" >
                        <img class="img-fluid "src={this.loadImageItem(info.url)} style={{height:100}} alt="img" onError={(e)=>e.target.src=window.$ImageBaseUrl+"/"+info.url}/>
                    </div>
                    <hr/>
                    <div className="card-body" style={{textAlign:'left',flexGrow:1,height:100,overflowY:'scroll'}}>
                        <p style={{color:'grey'}}>{info.description}</p>
                          <br />
                          
                    </div>
                  </div>

                </Paper>
                </div>
              ))}
              </div>
          </DialogContent>
        ):(
          <Bars/>
        )}
        <Divider/>
        <DialogActions>
        <Button onClick={this.props.upload} class="btn btn-primary">
        Upload Image
      </Button>
          <Button onClick={this.props.close} class="btn btn-secondary">
          Close
        </Button>
        </DialogActions>
      </Dialog>
      </React.Fragment>
    );
  }
}

UploadPhotoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadPhotoDialog);