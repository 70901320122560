import React from "react"
import ReactLoading from 'react-loading';

class MyLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  render(){
    return(
    <div class="d-flex justify-content-center wow fadeInUp">
    <ReactLoading type={"spin"} color={'rgba(52, 52, 52, 0.8)'} height={30} width={30} className="center"/>
    </div>
  )
    }
}
export default MyLoader