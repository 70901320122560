import React, { Component } from 'react';
import Drawer from '../components/Drawer';
export default class DashboardContainer extends Component {
	state = {
        brands:[],
		page:0,
		restUrl:window.$name,
        rowsPerPage:25,
		color: 'rgba(52, 52, 52, 0.8)',
		sToken:''
	  }
	  listenScrollEvent = e => {
		if (window.scrollY > 400) {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		} else {
		  this.setState({color: 'rgba(52, 52, 52, 0.8)'})
		}
	  }
	
	
	  componentDidMount(){
		const token= localStorage.getItem('token');
		if(token==null){
			this.props.pindahHalaman('login');
		}else{
			this.setState({
				sToken:token
			})
		}
		const name = window.$name;
        window.addEventListener('scroll', this.listenScrollEvent)
      }
    handleChangePage = (event, newPage) => {
        this.setState({
            page:newPage
        })
    };
    
	render() {
		return(
            <div>
            <Drawer halaman={this.props.halaman} pindahHalaman={this.props.pindahHalaman}>

            <h3>Dashboard</h3>
            </Drawer>
		  </div>
		)
	}
}
