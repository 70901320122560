import React, { Component } from 'react';
    import PropTypes from 'prop-types';
    import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '../components/Drawer';
import ModalProd from '../components/DialogProductDetail';
import ModalImageProd from '../components/DialogImage';
import ModalEditProd from '../components/DialogEditProduct';
import ModalDelProd from '../components/DialogDeleteItem';
import ModalDelImgProd from '../components/DialogDeleteItem';
import ModalAddProd from '../components/DialogAddProduct';
import ModalAddImgProd from '../components/DialogUploadPhoto';

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  formControl: {
    minWidth: 200,
  },
  formControl2: {
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class ProductContainer extends Component {
	state = {
        productFind:window.$productFind,
        productFindById:window.$productFindById,
        productDelete:window.$productDelete,
        brands:[],
        products: [],
        modalItem: [],
        brand:'',brandCategory:'',code:'',name:'',category:'',desc:'',seo:'',ids:'',brandName:'',imageUrl:'',url:'',idDelete:'',descImg:'',
        images:[],
        file:null,
        page:0,
        show: false,
        showImage: false,
        showAdd:false,
        showAddImg:false,
        showEdit:false,
        showDelete:false,showDeleteImg:false,
        avaliable:true,rowsPerPage:25,brandFilter:0,catFilter:null,
        searchField:'',isLoading: true,isDone: false,
    }
    handleFileChange = e => {
      const reader = new FileReader();
      var files=e.target.files[0];
      this.setState({
        file: e.target.files[0],
        url:e.target.files[0].name,
        imageUrl:URL.createObjectURL(e.target.files[0])
      })
      reader.onloadend = () => {
        this.setState({
          imageUrl: reader.result
        });
      }
    }
    setName = (e) => {
      this.setState({
          name: e.target.value
      })
   }
   setCode = (e) => {
      this.setState({
          code: e.target.value
      })
   }
   setBrandCategory = (e) => {
      this.setState({
          brandCategory: e.target.value
      })
   }
   setCategory = (e) => {
      this.setState({
          category: e.target.value
      })
   }
   setId = (e) => {
      this.setState({
          brand: e.target.value
      })
   }
   setDesc = (e) => {
      this.setState({
          desc: e.target.value
      })
   }
   setSeo = (e) => {
    this.setState({
        seo: e.target.value
    })
 }
   setDescImg = (e) => {
    this.setState({
        descImg: e.target.value
    })
 }
	
	  componentDidMount(){
        window.addEventListener('scroll', this.listenScrollEvent)
      this.loadItem();
      }
    handleChangePage = (event, newPage) => {
        this.setState({
            page:newPage
        })
    };
    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    };
    handleRefresh(){
      this.setState({
        products:[],
        isLoading:true
      })
      this.loadItem();
    }

    hadleSubmit=nr=>()=> {
      if(this.state.name !=''){
        if(this.state.code !=''){
          if(this.state.brandCategory !=''){
            if(this.state.category !=''){
              if(this.state.brand !=''){
                if(this.state.desc !=''){
                  this.setState({
                    showAdd : false
                });
                this.addIltem();
                }else{
                  alert('Please input description')
                }
              }else{
                alert('Please input brand id')
              }
            }else{
              alert('Please input category')
            }
          }else{
            alert('Please input brand category')
          }
        }else{
          alert('Please input code')
        }
      }else{
        alert('Please input name')
      }
      
    }
    hadleSubmitImage=nr=>()=> {
        if(this.state.file!= null){
          if(this.state.descImg!=''){
            this.setState({
              showAddImg : false,
              isLoading:true
          });
          this.uploadImage();
          }else{
            alert('please input description')
          }
        }else{
          alert('please input select image')
        }
      
    }
  
  uploadImage(){
      const{file,url,ids,descImg,idText,restUrl}= this.state;

      var formdata = new FormData();
  formdata.append("t", "token");
  formdata.append("eid", ids);
  formdata.append("file[]", file, url);
  formdata.append("file_desc[]", descImg)
  
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  };
  
  fetch(window.$productUploadImage, requestOptions)
    .then(response => response.json())
    .then(result =>
      {
        if(result.msg=="Success"){
          this.setState({
            url:'',brand:'',brandCategory:'',code:'',name:'',category:'',desc:'',ids:'',brandName:'',imageUrl:'',file:null,seo:'',
            isLoading:false
          })
          this.handleRefresh()
        }else{
          this.setState({
            isLoading:false
          })
        }
        }
      )
    .catch(error => console.log('error', error));
    }

    handleSubmitEdit(id){
      if(this.state.name !=''){
        if(this.state.code !=''){
          if(this.state.brandCategory !=''){
            if(this.state.category !=''){
              if(this.state.brand !=''){
                if(this.state.desc !=''){
                  this.setState({
                    showEdit: false
                  });
                  this.editItem(id);
                }else{
                  alert('Please input description')
                }
              }else{
                alert('Please input brand id')
              }
            }else{
              alert('Please input category')
            }
          }else{
            alert('Please input brand category')
          }
        }else{
          alert('Please input code')
        }
      }else{
        alert('Please input name')
      }
      
    }

    editItem(id){
      let keyword4 = parseInt(this.state.brand) ;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({"t":"token","id":id,"brand_id":keyword4,"code":this.state.code,"Name":this.state.name,"brand_cat":this.state.brandCategory,"category":this.state.category,"Description":this.state.desc,"seo":this.state.seo});
      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
      };

      fetch(window.$productUpdate, requestOptions)
      .then(response => response.json())
      .then(result =>
      {
      if(result.msg=="Success"){
        this.setState({
          brand:'',brandCategory:'',code:'',name:'',category:'',desc:'',ids:'',seo:''
        })
        this.handleRefresh()
      }else{
          alert('Edit item failed, please try again')
      }
      }
      )
    .catch(error => console.log('error', error));
    }

    addIltem(){
      
      let keyword4 = parseInt(this.state.brand) ;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({"t":"token","brand_id":keyword4,"brand_cat":this.state.brandCategory,"code":this.state.code,"name":this.state.name,"category":this.state.category,"description":this.state.desc,"seo":this.state.seo});
      var requestOptions = {method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

fetch(window.$productAdd, requestOptions)
  .then(response => response.json())
  .then(result =>
    {
      if(result.msg=="Success"){
        this.setState({
          brand:'',brandCategory:'',code:'',name:'',category:'',desc:'',ids:'',seo:''
        })
        this.handleRefresh()
      }else{
        alert('Add item failed, please try again')
        this.setState({
          brand:'',brandCategory:'',code:'',name:'',category:'',desc:'',ids:'',seo:''
        })
      }
      }
    )
  .catch(error => console.log('error', error));
    }
    loadItem(){
        let keyword=parseInt(this.state.brandFilter)
        let keyword2=this.state.searchField
        let keyword3=this.state.catFilter
        //console.log(keyword)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify({"t":"token"});
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        fetch(window.$productIndex, requestOptions)
        .then(response => response.json())
        .then(responseJson => {
          if(responseJson.result.length==0){
            this.setState({
                avaliable:true,
                isLoading:false
                });
        }else{
            this.setState({
                avaliable:false,
                products:responseJson.result,
                isLoading:false
            });
        }
        this.setState({
        isLoading:false
        });
        })
        .catch(error => console.log('error', error));
    }
    handleDeleteImage(id){
      this.setState({
        idDelete:id,
        showImage:false,
        showDeleteImg:true
      })
    }
    closeModalDeleteImgOk(){
      this.setState({
        showDeleteImg: false
      });
      this.deleteImage(this.state.idDelete);
    }
    deleteImage(id){
      this.setState({
        isLoading:true
      })
      const{productDelImage}=this.state;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({"t":"token","id":id});

      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$productDelImage, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status==true){
        this.setState({
          isLoading:false,
          idDelete:''
        })
        this.handleRefresh()
      }else{
        this.setState({
          isLoading:false,
          idDelete:''
        })
      }
    })
    .catch(error => console.log('error', error));
    }
    closeModal=nr=>()=> {
      this.setState({
          show : false
      });
    }

    closeModalAdd=nr=>()=> {
      this.setState({
          showAdd : false
      });
    }
    closeModalAddImg=nr=>()=> {
      this.setState({
          showAddImg : false
      });
    }
    handleModalAdd(id){
      this.setState({
        showAdd: true,
      });
    }
    closeModalEdit=nr=>()=> {
      this.setState({
        showEdit : false
      });
    }
    handleModalEdit(id){
      this.setState({
        showEdit: true,
        isDone:false,
        show:false
      });
      this.loadModal(id);
    }
    handleModalImage(id){
      this.setState({
        showImage: true,
        isDone:false,
      });
      this.loadModal(id);
    }
    closeModalImage=nr=>()=> {
      this.setState({
        showImage : false
      });
    }
    closeModalDelete=nr=>()=> {
      this.setState({
        showDelete : false
      });
    }
    closeModalDeleteImage=nr=>()=> {
      this.setState({
        showDeleteImg : false,
        idDelete:''
      });
    }
    handleModalDelte(id){
      this.setState({
        showDelete: true,
        show:false
      });
      this.loadModal(id);
    }
    handleModalUpload(id){
      this.setState({
        showAddImg: true,
        showImage:false
      });
      this.loadModal(id);
    }
    closeModalDeleteOk(id){
      this.setState({
        showDelete: false
      });
      this.deleteItem(id);
    }
    handleModal(id){
      this.setState({
        show: true,
        isDone:false
       
      });
      this.loadModal(id);
    }

    deleteItem(id){
      const{productDelete}=this.state;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({"t":"token","id":id});

      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(productDelete, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status==true){
        this.handleRefresh()
      }
    })
    .catch(error => console.log('error', error));
    }

    loadModal(id){
      const{productFindById,brand,brandCategory,code,name,category,desc}=this.state;
      var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"t":"token","id":id});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(productFindById, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          brand:result.result[0].brand_id,
          ids:result.result[0].id,
          brandCategory:result.result[0].brand_cat,
          name:result.result[0].name,
          code:result.result[0].code,
          category:result.result[0].category,
          desc:result.result[0].description,
          brandName:result.result[0].brand.name,
          images:result.result[0].images,
          seo:result.result[0].seo,
          modalItem:result.result,
          isDone:true
         
        })
        })
      .catch(error => console.log('error', error));
    }

    loadImage(images){
      if(images[0]!=null){
        return images[0].url;
      }
      return "No Images";
    }
    loadImageItem(images){
      if(images[0]!=null){
        var urls=images[0].url.split('/')
        var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+urls[3]+"/"+"S"+"/"+urls[4]
        return img
      }
      return require('../assets/placeholder.jpg');
    }
    checkImage(images){
      if(images[0]!=null){
        return true
      }
      return false
    }

	render() {
        const { avaliable,brands,page,rowsPerPage,products,modalItem,isDone,isLoading} = this.state;
        const { classes } = this.props;
		return(
            <div>
            <Backdrop open={isLoading}>
            <CircularProgress color="inherit" />
            </Backdrop>
            <div>
            <Drawer halaman={this.props.halaman} pindahHalaman={this.props.pindahHalaman} modal={ModalProd}>
            <div>
            <h3>Products</h3>
            </div>
            <Paper elevation={3} >
            {isLoading?(
              <div></div>
            ):(
              <div>
              {avaliable ? (
                <div>
                <div className="container d-flex justify-content-center">
                <h2>Item not Avaliable</h2>
                </div>
                <div className="container d-flex justify-content-center">
                <Button variant="contained" key="1" color="primary"style={{marginTop:10,marginBottom:10,marginLeft:10}} onClick={e=>this.handleModalAdd(e)}>
              Add
              </Button>
              </div>
              </div>
              ):(
                <div>
                <div >
                <Button defaultValue="1" variant="contained" color="primary"style={{marginTop:10,marginBottom:10,marginLeft:10}} onClick={()=>this.handleModalAdd()}>
                Add
                </Button>
                <Button variant="contained" color="secondary" style={{marginTop:10,marginBottom:10}} onClick={()=>this.handleRefresh()}>
                Refresh
                </Button>
                </div>
                <TableContainer>
                <Table aria-label="simple table" className="table table-striped" style={{minWidth:700}}>
                <TableHead>
                <TableRow>
                <TableCell style={{fontWeight:'bold'}}>name</TableCell>
                <TableCell style={{fontWeight:'bold'}}>code</TableCell>
                <TableCell style={{fontWeight:'bold'}}>category</TableCell>
                <TableCell style={{fontWeight:'bold'}}>brand</TableCell>
                <TableCell style={{fontWeight:'bold'}}>brand_cat</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Image</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Description</TableCell>
                </TableRow>
                </TableHead>
               <TableBody>
               {products.slice(page*rowsPerPage,page*rowsPerPage+rowsPerPage)
                .map((items)=>(
                    <TableRow>
                    <TableCell><a href="#" class="text-primary" onClick={()=>this.handleModal(items.id)}>{items.name}</a></TableCell>
                    <TableCell>{items.code}</TableCell>
                    <TableCell>{items.category}</TableCell>
                    <TableCell>{items.brand.name}</TableCell>
                    <TableCell>{items.brand_cat}</TableCell>
                    <TableCell>{this.checkImage(items.images)?(<a href="#" class="text-primary" onClick={()=>this.handleModalImage(items.id)}><img src={this.loadImageItem(items.images)} style={{height:50,maxWidth:100}} alt="img" onError={(e)=>e.target.src=window.$ImageBaseUrl+"/"+items.images[0].url}/></a>):(<div><Button onClick={()=>this.handleModalUpload(items.id)} class="btn btn-primary">Upload Image</Button></div>)}</TableCell>
                    <TableCell>{items.description}</TableCell>
                    </TableRow>
                ))}
               </TableBody> 
                </Table>
                </TableContainer>
                <TablePagination
                component="div"
                count={products.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                
    
                </div>
              )}
              </div>
            )}
            </Paper>
            { modalItem.map((item)=>(
              <ModalProd
              show={this.state.show}
              close={this.closeModal()}
              edit={()=>this.handleModalEdit(item.id)}
              delete={()=>this.handleModalDelte(item.id)}
              upload={()=>this.handleModalUpload(item.id)}
              done={isDone}
              name={item.name}
              id={item.id}
              code={item.code}
              brandId={item.brand.name}
              brandCat={item.brand_cat}
              category={item.category}
              desc={item.description}
              seo={item.seo}
              url={this.loadImage(item.images)}
              />
            ))
            }
              <ModalEditProd
              show={this.state.showEdit}
              close={this.closeModalEdit()}
              done={isDone}
              name={this.state.name}
              id={this.state.ids}
              code={this.state.code}
              brandId={this.state.brand}
              brandCat={this.state.brandCategory}
              category={this.state.category}
              desc={this.state.desc}
              seo={this.state.seo}
              brandNm={this.state.brandName}
              nameChange={e=>this.setName(e)}
              codeChange={e => this.setCode(e)}
              brandChange={e => this.setId(e)}
              brandCatChange={e=> this.setBrandCategory(e)}
              categoryChange={e => this.setCategory(e)}
              descChange={e => this.setDesc(e)}
              seoChange={e => this.setSeo(e)}
              submit={()=>this.handleSubmitEdit(this.state.ids)}
              />
            { modalItem.map((item)=>(
              <ModalDelProd
              show={this.state.showDelete}
              close={this.closeModalDelete()}
              ok={()=>this.closeModalDeleteOk(item.id)}
              text={"product"}
              />
            ))
            }
            <ModalDelImgProd
              show={this.state.showDeleteImg}
              close={this.closeModalDeleteImage()}
              ok={()=>this.closeModalDeleteImgOk()}
              text={"product photo"}
              />
            <ModalImageProd
            done={isDone}
            show={this.state.showImage}
            close={this.closeModalImage()}
            images={this.state.images}
            upload={()=>this.handleModalUpload(this.state.ids)}
            delete={this}
            />
            <ModalAddImgProd
            show={this.state.showAddImg}
            close={this.closeModalAddImg()}
            imageUrl={this.state.imageUrl}
            fileChange={(e)=>this.handleFileChange(e)}
            descChange={e=>this.setDescImg(e)}
            submit={this.hadleSubmitImage()}
            />
            <ModalAddProd
            show={this.state.showAdd}
            close={this.closeModalAdd()}
            name={e=>this.setName(e)}
            code={e => this.setCode(e)}
            brandCat={e=> this.setBrandCategory(e)}
            category={e => this.setCategory(e)}
            brand={e => this.setId(e)}
            desc={e => this.setDesc(e)}
            seo={e => this.setSeo(e)}
            submit={this.hadleSubmit()}/>
        </Drawer>
        </div>
        <div>
        
        </div>
		  </div>
		)
	}
}
ProductContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductContainer) ;