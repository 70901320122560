import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import './Login.css'
const logo=require('../assets/logo.png');

const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
    },
    dense: {
      marginTop: 16,
    },
    menu: {
      width: 200,
    },
  });

class LoginContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brandUpdate:window.$brandUpdate,
            open: false,
            isAdd:true,
            file:null,
            uName:'',
            pass:'',
            isLoading: false
        };
      }

      setUsername = (e) => {
        this.setState({
            uName: e.target.value
        })
     }
     
     setPassword = (e) => {
        this.setState({
            pass: e.target.value
        })
     }
     handleClick(event){
       this.hadleSubmit()
     }
     hadleSubmit=nr=>()=> {
      if(this.state.uName!=''){
        if(this.state.pass!= ''){
          this.setState({
            isLoading:true
          })
          this.loginAction();
        }else{
          
          alert('please input password')
        }
      }else{
        alert('please input username ')
      }
      
    }
    loginAction(){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({"username":this.state.uName,"password":this.state.pass});

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(window.$login, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
        if(result.status==true){
          this.setState({
            isLoading:false
          })
          localStorage.setItem('token', result.result[1]);
          this.props.pindahHalaman('home');
        }else{
          alert('Username or password wrong')
          this.setState({
            isLoading:false,
            uName:'',pass:''
          })
        }
        })
        .catch(error => console.log('error', error));
    }
    handleKeyPress = (event) => {
      if(event.keyCode === 13){
        this.handleClick(event)
        console.log('hahah')
      }
    }
  render() {
    const { classes } = this.props;
    const {isLoading} = this.state;
    return (
      <div>

      <div class="vertical-center">

      <div className="container">
      <Backdrop open={isLoading}>
      <CircularProgress color="inherit" />
      </Backdrop>
        <div className="row">
        <div className="col-sm-4"></div>
        <div className="col-sm-4 containerOut">
        {isLoading ? (<div></div>):(
          <Paper  className="align-middle" style={{paddingBottom:30}}>
        <div>
        <div style={{backgroundColor:'#d6d5d2',padding:20}}>
        <div className="headerItem containerItem" style={{borderStyle:'ridge'}}>
        <img src={logo} style={{height:80,marginTop:10,marginBottom:10}}/>
        </div>
        </div>
        <div style={{marginLeft:10,marginRight:10}}>
        <TextField label="Username" type="text" fullWidth margin="normal" variant="outlined" value={this.state.uName} onChange={(e)=> this.setUsername(e)}/>
        <TextField label="Password" type="password" fullWidth margin="normal" variant="outlined" value={this.state.pass}  onChange={(e)=> this.setPassword(e)} onKeyPress={event=> {if(event.keyCode==13){this.handleClick(event)}}}/>
        <Button variant="contained" color="primary" fullWidth onClick={this.hadleSubmit()}>
        Login
        </Button>

        </div>
        </div>
        </Paper>)}
        
        </div>
        <div className="col-sm-4"></div>
        </div>
      </div>
      </div>
      </div>
    );
  }
}

LoginContainer.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(LoginContainer);