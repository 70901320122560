import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Bars from './BarLoading';
import './Modal.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class UploadPhotoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            file:null,
            idText:this.props.idText,
            restUrl:this.props.restUrl,
            show:this.props.show,
            url:'',
            isAdd:true,
            imageUrl:'',
            desc:'',
            ids:'',
        };
      }

  render() {
    const { classes } = this.props;
    const {isAdd} = this.state;
    let imgPreview;
        if (this.props.imageUrl) {
            imgPreview = <img src={this.props.imageUrl} alt='' style={{height:80}}/>;
        }
    return (
      <div>
        <Dialog
          open={this.props.show}
          onClose={this.props.close}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title">Upload Photo</DialogTitle>
          <Divider/>
          {isAdd ? (
            <DialogContent>
            <form>
          <div className="form-group preview">
                      {imgPreview}
          </div>
          <div className="">
          <input className="ml-2"  name="images" type="file" onChange={this.props.fileChange}/>
          <TextField label="Description" className={classes.textField} type="text" multiline fullWidth margin="normal" variant="outlined" onChange = {this.props.descChange}/>
          </div>
          </form>
            </DialogContent>
          ):(
            <Bars/>
          )}
          
          <Divider/>
          <DialogActions>
            <Button onClick={this.props.close} class="btn btn-secondary">
              Close
            </Button>
            <Button onClick={this.props.submit} class="btn btn-danger">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UploadPhotoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadPhotoDialog);