import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Bars from './BarLoading';
import './Modal.css';
import Drawer from '../components/Drawer';


class DraggableDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isOpen: false,
          name:'',
          code:'',
          brandCategory:'',
          brand:'',
          desc:'',
        };
      }

      setName = (e) => {
        this.setState({
            name: e.target.value
        })
     }
     setCode = (e) => {
        this.setState({
            code: e.target.value
        })
     }
     setBrandCategory = (e) => {
        this.setState({
            brandCategory: e.target.value
        })
     }
     setCategory = (e) => {
        this.setState({
            category: e.target.value
        })
     }
     setId = (e) => {
        this.setState({
            brand: e.target.value
        })
     }
     setDesc = (e) => {
        this.setState({
            desc: e.target.value
        })
     }

      closeModal=nr=>()=> {
        this.setState({
            isOpen : false
        });
      }
      handleModal(){
        this.setState({
          isOpen: true,
        });
      }

  render() {
    const {isOpen}= this.state;
    return (
      <div>
        <Dialog
          open={this.props.show}
          onClose={this.props.close}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title">{this.props.name}</DialogTitle>
          <Divider/>
          {this.props.done?(
            <DialogContent>
            <TableContainer>
            <Table aria-label="simple table" className="table table-striped" style={{minWidth:650}}>
            <TableBody>
            <TableRow>
            <TableCell>Brand ID</TableCell>
            <TableCell>{this.props.brandId}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>Brand Category</TableCell>
            <TableCell>{this.props.brandCat}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>Category</TableCell>
            <TableCell>{this.props.category}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{this.props.name}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>Code</TableCell>
            <TableCell>{this.props.code}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>{this.props.desc}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>SEO</TableCell>
            <TableCell>{this.props.seo}</TableCell>
            </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
            </DialogContent>
          ):(
            <Bars/>
          )}
          <Divider/>
          <DialogActions>
            <Button onClick={this.props.edit} class="btn btn-primary">
              Edit
            </Button>
            <Button onClick={this.props.delete} class="btn btn-danger">
            Delete
          </Button>
            <Button onClick={this.props.close} class="btn btn-secondary">
            Close
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DraggableDialog;