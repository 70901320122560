import React, { Component } from 'react';
import './App.css';
import Contents from './containers/WebContentContainer'
import Brands from './containers/BrandContainer'
import BrandPhoto from './containers/BrandPhotoContainer'
import Dashboard from './containers/DashboardContainer'
import Customer from './containers/CustomerContainer'
import Event from './containers/EventConatiner'
import Story from './containers/StoryContainer'
import Contact from './containers/ContactContainer'
import Product from './containers/ProductContainer'
import PicMap from './containers/PicMapContainer'
import ProductPhoto from './containers/ProductPhotoContainer'
import Try from './containers/TryContainer'
import Login from './containers/LoginContainer'

class App extends Component {
  state = {
    page: "home"
  }

  ganti = (page)=> {
    this.setState({
      page: page,
      appSetting:{}
    })
  }
  componentDidMount(){
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };

    fetch("./json_dummy/application.json", requestOptions)
    .then(response => response.json())
    .then(responseJson => {
        this.setState({
          NavMenu:responseJson
        });
    })

    // fetch('./json_dummy/application.json')
    //   .then((res) => res.json())
    //   .then((data) => {
    //     console.log('data:', data);
    //   })
  }

  PageLoad = () => {

    //var MyComponent = React.createElement("Home", {});
    var Tag = `HomeContainer`;
    //const Mypage = mpage;
    return <Tag />
  }
  render(){
  return (
    <div>
      <div>
      {this.state.page ==='home' && <Dashboard appSetting={this.state.appSetting} halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'brands' &&<Brands halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'picmap' &&<PicMap halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'content' &&<Contents halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'customer' &&<Customer halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'event' &&<Event halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'story' &&<Story halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'contact' &&<Contact halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'brandphoto' &&<BrandPhoto halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'product' &&<Product halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'productphoto' &&<ProductPhoto halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'try' &&<Try halaman={this.state.page} pindahHalaman={this.ganti}/>}
      {this.state.page === 'login' &&<Login halaman={this.state.page} pindahHalaman={this.ganti}/>}
      </div>
    </div>
  );
  }
}

export default App;
