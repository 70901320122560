import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '../components/Drawer';
import ModalProd from '../components/DialogEventDetail'
import ModalImageProd from '../components/DialogImage';
import ModalVideoProd from '../components/DialogVideo';
import ModalDelImgProd from '../components/DialogDeleteItem';
import ModalDelVidProd from '../components/DialogDeleteItem';
import ModalEditProd from '../components/DialogEditItem'
import ModalDelProd from '../components/DialogDeleteItem';
import ModalAdd from '../components/DialogAddItem';
import ModalAddVideo from '../components/DialogAddItem';
import ModalAddImgBrand from '../components/DialogUploadPhoto';

export default class BrandContainer extends Component {
	state = {
        show:false,showImage: false,showVideo:false,showAdd:false,showAddImg:false,showAddVideo:false,showEdit:false,showDelete:false,showDeleteVid:false,showDeleteImg:false,isDone:false,avaliable:true,
        name:'',desc:'',ids:'',imageUrl:'',url:'',file:null,descImg:'',idDelete:'',videoUrl:'',videoDesc:'',
        events:[],images:[],videos:[],modalItem:[],
        brandIndex:window.$brandIndex,
        page:0,rowsPerPage:25,isLoading: true,
	  }
    handleChangePage = (event, newPage) => {
        this.setState({
            page:newPage
        })
    };
    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    };
    setName = (e) => {
      this.setState({
          name: e.target.value
      })
    }
    setId = (e) => {
      this.setState({
          ids: e.target.value
      })
    }
    setDesc = (e) => {
      this.setState({
          desc: e.target.value
      })
    }
    setDescImg = (e) => {
      this.setState({
          descImg: e.target.value
      })
    }
    setVideoUrl = (e) => {
      this.setState({
          videoUrl: e.target.value
      })
    }
    setVideoDesc = (e) => {
      this.setState({
          videoDesc: e.target.value
      })
    }
    componentDidMount(){
      this.loadItem();
    }
    loadItem(){
      const{brandIndex}=this.state
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({"t":"token"});
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(window.$eventIndex, requestOptions)
        .then(response => response.json())
        .then(responseJson => {
          if(responseJson.result.length==0){
            this.setState({
                avaliable:true,
                isLoading:false
                });
        }else{
            this.setState({
                avaliable:false,
                events:responseJson.result,
                isLoading:false
            });
        }
        this.setState({
          isLoading:false
        });
        })
        .catch(error => console.log('error', error));
    }
    addItem(){
      var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({"t":"token","name":this.state.name,"description":this.state.desc});

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };
    fetch(window.$eventAdd, requestOptions)
    .then(response => response.json())
    .then(result =>
    {
      if(result.msg=="Success"){
      this.setState({
        name:'',desc:'',ids:'',
      })
      this.handleRefresh()
      }else{
      this.setState({
        name:'',desc:'',ids:'',
      })
      }
    }
    )
    .catch(error => console.log('error', error));
    }
    loadModal(id){
      var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"t":"token","id":id});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(window.$eventFindById, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          ids:result.result[0].id,
          name:result.result[0].name,
          desc:result.result[0].description,
          images:result.result[0].images,
          videos:result.result[0].videos,
          modalItem:result.result,
          isDone:true
         
        })
        })
      .catch(error => console.log('error', error));
    }
    deleteItem(id){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({"t":"token","id":id});

      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$eventDelete, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status==true){
        this.handleRefresh()
      }
    })
    .catch(error => console.log('error', error));
    }
    editItem(id){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({"t":"token","id":id,"Name":this.state.name,"Description":this.state.desc});
      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
      };

      fetch(window.$eventUpdate, requestOptions)
      .then(response => response.json())
      .then(result =>
      {
      if(result.msg=="Success"){
        this.setState({
          name:'',desc:'',ids:'',
        })
        this.handleRefresh()
      }else{
        this.setState({
          name:'',desc:'',ids:'',
        })
      }
      }
      )
      .catch(error => console.log('error', error));
    }
    uploadImage(){
      const{file,url,ids,descImg,idText,restUrl}= this.state;

      this.setState({
        isAdd:false
      })

      var formdata = new FormData();
      formdata.append("t", "token");
      formdata.append("eid", ids);
      formdata.append("file[]", file, url);
      formdata.append("file_desc[]", descImg)
  
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
  
    fetch(window.$eventUploadImage, requestOptions)
    .then(response => response.json())
    .then(result =>
      {
        if(result.msg=="Success"){
          this.setState({
            url:'', imageUrl:'', desc:'', ids:'',file:null,
            isLoading:false
          })
          this.handleRefresh()
        }else{
          this.setState({
            isLoading:false
          })
        }
        }
      )
    .catch(error => console.log('error', error));
    }
    uploadVideo(){
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({"t":"token","eid":this.state.ids,"url":this.state.videoUrl,"description":this.state.videoDesc});
  
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
  
    fetch(window.$eventAddVideo, requestOptions)
    .then(response => response.json())
    .then(result =>
      {
        console.log(result)
        if(result.msg=="Success"){
          this.setState({
            name:'',desc:'',ids:'',imageUrl:'',url:'',file:null,descImg:'',idDelete:'',videoUrl:'',videoDesc:'',
            isLoading:false
          })
          this.handleRefresh()
        }else if(result.status==false){
          alert("Video url already input or upload failed")
          this.setState({
            isLoading:false
          })
        }
        }
      )
    .catch(error => console.log('error', error));
    }
    deleteImage(id){
      this.setState({
        isLoading:true
      })
      const{productDelImage}=this.state;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({"t":"token","id":id});

      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$eventDelImage, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status==true){
        this.setState({
          isLoading:false,
          idDelete:''
        })
        this.handleRefresh()
      }else{
        this.setState({
          isLoading:false,
          idDelete:''
        })
      }
    })
    .catch(error => console.log('error', error));
    }
    deleteVideo(id){
      this.setState({
        isLoading:true
      })
      const{productDelImage}=this.state;
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({"t":"token","id":id});

      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$eventDelVideo, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status==true){
        this.setState({
          isLoading:false,
          idDelete:''
        })
        this.handleRefresh()
      }else{
        this.setState({
          isLoading:false,
          idDelete:''
        })
      }
    })
    .catch(error => console.log('error', error));
    }
    handleRefresh(){
      this.setState({
        events:[],
        isLoading:true
      })
      this.loadItem();
    }
    handleModal(id){
      this.setState({
        show:true,
        isDone:false
      });
      this.loadModal(id);
    }
    closeModal=nr=>()=> {
      this.setState({
          show : false
      });
    }    
    hadleSubmit=nr=>()=> {
      if(this.state.name!=''){
        if(this.state.desc!=''){
          this.setState({
            showAdd : false
        });
        this.addItem();
        }else{
          alert('please input description')
        }
      }else{
        alert('please input name')
      }
      
    }
    handleModalAdd(){
      this.setState({
        showAdd: true,
      });
    }
    closeModalAdd=nr=>()=> {
      this.setState({
          showAdd : false
      });
    }
    handleModalEdit(id){
      this.setState({
        showEdit: true,
        isDone:false,
        show:false
      });
      this.loadModal(id);
    }
    closeModalEdit=nr=>()=> {
      this.setState({
        showEdit : false
      });
    }
    handleSubmitEdit(id){
      if(this.state.name!=''){
        if(this.state.desc!=''){
          this.setState({
            showEdit: false
          });
          this.editItem(id);
        }else{
          alert('please input description')
        }
      }else{
        alert('please input name')
      }
    }
    handleModalDelte(id){
      this.setState({
        showDelete: true,
        show:false
      });
      this.loadModal(id);
    }
    closeModalDelete=nr=>()=> {
      this.setState({
        showDelete : false
      });
    }
    closeModalDeleteOk(id){
      this.setState({
        showDelete: false
      });
      this.deleteItem(id);
    }
    handleModalUpload(id){
      this.setState({
        showAddImg: true,
        showImage:false
      });
      this.loadModal(id);
    }
    closeModalAddImg=nr=>()=> {
      this.setState({
          showAddImg : false
      });
    }    
    hadleSubmitImg=nr=>()=> {
        if(this.state.file!= null){
          if(this.state.descImg!=''){
            this.setState({
              showAddImg : false,
              isLoading:true
          });
          this.uploadImage();
          }else{
            alert('please input description')
          }
        }else{
          alert('please input select image')
        }
    }
    handleModalUploadVideo(id){
      this.setState({
        showAddVideo: true,
        showVideo:false
      });
      this.loadModal(id);
    }
    closeModalAddVideo=nr=>()=> {
      this.setState({
        showAddVideo : false
      });
    }    
    handleSubmitVideo=nr=>()=> {
        if(this.state.videoUrl!=''){
          if(this.state.videoDesc!=''){
            this.setState({
              showAddVideo : false,
              isLoading:true
            });
            this.uploadVideo();
          }else{
            alert('please input description')
          }
            
        }else{
          alert('please input video url')
        }
    }
    handleDeleteImage(id){
      this.setState({
        idDelete:id,
        showImage:false,
        showDeleteImg:true
      })
    }
    closeModalDeleteImgOk(){
      this.setState({
        showDeleteImg: false
      });
      this.deleteImage(this.state.idDelete);
    }
    
    closeModalDeleteImage=nr=>()=> {
      this.setState({
        showDeleteImg : false,
        idDelete:''
      });
    }
    handleDeleteVideo(id){
      this.setState({
        idDelete:id,
        showVideo:false,
        showDeleteVid:true
      })
    }
    closeModalDeleteVidOk(){
      this.setState({
        showDeleteVid: false
      });
      this.deleteVideo(this.state.idDelete);
    }
    
    closeModalDeleteVideo=nr=>()=> {
      this.setState({
        showDeleteVid : false,
        idDelete:''
      });
    }
    handleModalImage(id){
      this.setState({
        showImage: true,
        isDone:false,
      });
      this.loadModal(id);
    }
    closeModalImage=nr=>()=> {
      this.setState({
        showImage : false
      });
    }
    handleModalVideo(id){
        this.setState({
          showVideo: true,
          isDone:false,
        });
        this.loadModal(id);
      }
      closeModalVideo=nr=>()=> {
        this.setState({
          showVideo : false
        });
      }
    handleFileChange = e => {
      const reader = new FileReader();
      var files=e.target.files[0];
      this.setState({
        file: e.target.files[0],
        url:e.target.files[0].name,
        imageUrl:URL.createObjectURL(e.target.files[0])
      })
      reader.onloadend = () => {
        this.setState({
          imageUrl: reader.result
        });
        
      }
    }
    loadVideoItem(videos){
        if(videos[0]!=null){
          return videos[0].url;
        }
        return "no video";
    }
    checkVideo(videos){
          if(videos[0]!=null){
            return true;
          }
          return false;
    }
    loadImage(images){
        if(images[0]!=null){
          return images[0].url;
        }
        return "No Images";
      }
    loadImageItem(images){
      if(images[0]!=null){
        var urls=images[0].url.split('/')
        var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+urls[3]+"/"+"S"+"/"+urls[4]
        return img
      }
      return require('../assets/placeholder.jpg');
    }
    checkImage(images){
        if(images[0]!=null){
          return true;
        }
        return false;
      }
	render() {
        const { events,page,rowsPerPage,isLoading,modalItem,isDone,avaliable} = this.state;
		return(
            <div>
            <Backdrop open={isLoading}>
            <CircularProgress color="inherit" />
            </Backdrop>
            <Drawer halaman={this.props.halaman} pindahHalaman={this.props.pindahHalaman}>
            <div>
            <h3>Events</h3>
            </div>
            <Paper elevation={3}>
            {isLoading?(
              <div></div>
            ):(
              <div>
              {avaliable ? (
                <div>
                <div className="container d-flex justify-content-center">
                <h2>Item not Avaliable</h2>
                </div>
                <div className="container d-flex justify-content-center">
                <Button variant="contained" color="primary"style={{marginTop:10,marginBottom:10,marginLeft:10}} onClick={()=>this.handleModalAdd()}>
              Add
              </Button>
              </div>
              </div>
              ) :(
                <div>
                <div >
                <Button variant="contained" color="primary"style={{marginTop:10,marginBottom:10,marginLeft:10}} onClick={()=>this.handleModalAdd()}>
                Add
                </Button>
                <Button variant="contained" color="secondary" style={{marginTop:10,marginBottom:10}} onClick={()=>this.handleRefresh()}>
                Refresh
                </Button>
                </div>
                <TableContainer>
                <Table aria-label="simple table" className="table table-striped" style={{minWidth:650}}>
                <TableHead>
                <TableRow>
                <TableCell style={{fontWeight:'bold'}}>Name</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Image</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Video</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Description</TableCell>
                </TableRow>
                </TableHead>
               <TableBody>
               {events.slice(page*rowsPerPage,page*rowsPerPage+rowsPerPage)
                .map((items)=>(
                    <TableRow>
                    <TableCell><a href="#" class="text-primary" onClick={()=>this.handleModal(items.id)}>{items.name}</a></TableCell>
                    <TableCell>{this.checkImage(items.images)?(<a href="#" class="text-primary" onClick={()=>this.handleModalImage(items.id)}><img src={this.loadImageItem(items.images)} style={{height:50,maxWidth:100}} alt="img" onError={(e)=>e.target.src=window.$ImageBaseUrl+"/"+items.images[0].url}/></a>):(<div><Button onClick={()=>this.handleModalUpload(items.id)} class="btn btn-primary">Upload Image</Button></div>)}</TableCell>
                    <TableCell>{this.checkVideo(items.videos)?(<a href="#" class="text-primary" onClick={()=>this.handleModalVideo(items.id)}>{this.loadVideoItem(items.videos)}</a>):(<div><Button onClick={()=>this.handleModalUploadVideo(items.id)} class="btn btn-primary">Upload video</Button></div>)}</TableCell>
                    <TableCell>{items.description}</TableCell>
                    </TableRow>
                ))}
               </TableBody> 
                </Table>
                </TableContainer>
                <TablePagination
                component="div"
                count={events.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />

                </div>
              )}
              </div>)}
            </Paper>
            { modalItem.map((item)=>(
              <ModalProd
              show={this.state.show}
              close={this.closeModal()}
              edit={()=>this.handleModalEdit(item.id)}
              delete={()=>this.handleModalDelte(item.id)}
              upload={()=>this.handleModalUpload(item.id)}
              uploadVideo={()=>this.handleModalUploadVideo(item.id)}
              done={isDone}
              name={item.name}
              id={item.id}
              createAt={item.created_at}
              updateAt={item.updated_at}
              desc={item.description}
              image={this.loadImage(item.images)}
              video={this.loadVideoItem(item.videos)}
              />
            ))
            }
            { modalItem.map((item)=>(
              <ModalDelProd
              show={this.state.showDelete}
              close={this.closeModalDelete()}
              ok={()=>this.closeModalDeleteOk(item.id)}
              text={"event"}
              />
            ))
            }
              <ModalEditProd
              show={this.state.showEdit}
              close={this.closeModalEdit()}
              done={isDone}
              title={"Edit Brand"}
              name={this.state.name}
              id={this.state.ids}
              desc={this.state.desc}
              nameChange={e=>this.setName(e)}
              descChange={e=>this.setDesc(e)}
              submit={()=>this.handleSubmitEdit(this.state.ids)}
              />
              <ModalDelImgProd
              show={this.state.showDeleteImg}
              close={this.closeModalDeleteImage()}
              ok={()=>this.closeModalDeleteImgOk()}
              text={"Event photo"}
              />
              <ModalDelVidProd
              show={this.state.showDeleteVid}
              close={this.closeModalDeleteVideo()}
              ok={()=>this.closeModalDeleteVidOk()}
              text={"Event video"}
              />
            <ModalImageProd
            done={isDone}
            show={this.state.showImage}
            close={this.closeModalImage()}
            images={this.state.images}
            upload={()=>this.handleModalUpload(this.state.ids)}
            delete={this}
            />
            <ModalVideoProd
            done={isDone}
            show={this.state.showVideo}
            close={this.closeModalVideo()}
            videos={this.state.videos}
            uploadVideo={()=>this.handleModalUploadVideo(this.state.ids)}
            delete={this}
            />
            <ModalAdd
            show={this.state.showAdd}
            close={this.closeModalAdd()}
            label1={"Event Name"}
            label2={"Description"}
            title={"Add Event"}
            name={e=>this.setName(e)}
            desc={e=>this.setDesc(e)}
            submit={this.hadleSubmit()}
            />
            <ModalAddVideo
            show={this.state.showAddVideo}
            close={this.closeModalAddVideo()}
            label1={"Video url"}
            label2={"Description"}
            title={"Add Video"}
            name={e=>this.setVideoUrl(e)}
            desc={e=>this.setVideoDesc(e)}
            submit={this.handleSubmitVideo()}
            />
            <ModalAddImgBrand
            show={this.state.showAddImg}
            close={this.closeModalAddImg()}
            imageUrl={this.state.imageUrl}
            fileChange={(e)=>this.handleFileChange(e)}
            descChange={(e)=>this.setDescImg(e)}
            submit={this.hadleSubmitImg()}
            />
            </Drawer>
		  </div>
		)
	}
}
