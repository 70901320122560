import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Bars from './BarLoading';
import './Modal.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class UploadPhotoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            brandAdd:window.$brandAdd,
            open: false,
            file:null,
            name:'',
            code:'',
            brandCategory:'',
            brand:'',
            desc:'',
            isAdd:true,
            show:this.props.show
        };
      }

      setName = (e) => {
        this.setState({
            name: e.target.value
        })
     }
     setCode = (e) => {
        this.setState({
            code: e.target.value
        })
     }
     setBrandCategory = (e) => {
        this.setState({
            brandCategory: e.target.value
        })
     }
     setCategory = (e) => {
        this.setState({
            category: e.target.value
        })
     }
     setId = (e) => {
        this.setState({
            brand: e.target.value
        })
     }
     setDesc = (e) => {
        this.setState({
            desc: e.target.value
        })
     }
    
     handleClick(event){
      this.setState({
        isAdd:false
      })
      const {brandAdd}=this.state
        let keyword1 = this.state.name;
        let keyword2 = this.state.code;
        let keyword3 = this.state.category;
        let keyword4 = parseInt(this.state.brand) ;
        let keyword5 = this.state.desc;
        var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

var raw = JSON.stringify({"t":"token","name":this.state.name,"description":this.state.desc});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(window.$brandAdd, requestOptions)
.then(response => response.json())
.then(result =>
  {
    if(result.msg=="Success"){
      this.setState({
        isAdd:true,
        show:false
      })
    }else{
      this.setState({
        isAdd:true
      })
    }
    }
  )
  .catch(error => console.log('error', error));

     }
     loadImageItem(images){
        if(images[0]!=null){
          return window.$ImageBaseUrl+"/"+images[0].url;
        }
        return require('../assets/placeholder.jpg');
      }
  render() {
    return (
      <React.Fragment>
      <Dialog
        open={this.props.show}
        onClose={this.props.close}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">Video</DialogTitle>
        <Divider/>
        {this.props.done?(
          <DialogContent>
          <TableContainer>
          <Table aria-label="simple table" className="table table-striped" style={{minWidth:650}}>
          
          <TableHead>
                <TableRow>
                <TableCell style={{fontWeight:'bold'}}>Action</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Video Url</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Description</TableCell>
                </TableRow>
        </TableHead>
        <TableBody>
                {this.props.videos.map((info)=>(
                    <TableRow>
                    <TableCell><Button onClick={e=>this.props.delete.handleDeleteVideo(info.id)} class="btn btn-danger">Delete</Button></TableCell>
                    <TableCell>{info.url}</TableCell>
                    <TableCell>{info.description}</TableCell>
                    </TableRow>
                ))}
          </TableBody>
          </Table>
          </TableContainer>
          </DialogContent>
        ):(
          <Bars/>
        )}
        <Divider/>
        <DialogActions>
        <Button onClick={this.props.uploadVideo} class="btn btn-primary">
        Add Video
      </Button>
          <Button onClick={this.props.close} class="btn btn-secondary">
          Close
        </Button>
        </DialogActions>
      </Dialog>
      </React.Fragment>
    );
  }
}

UploadPhotoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadPhotoDialog);