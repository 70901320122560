import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Bars from './BarLoading';
import './Modal.css';
const useStyles = makeStyles({
    container : {
        position: "relative",
        top: 0,
        left: 0,
        flex:1,
    },
    mainImage: {
         position: "relative",
         minWidth:'100%'
         //top: 0,
         //left: 0,
         //border: "1px red solid"
    },
    pinImage: {
            position: "absolute",
            //border: "1px green solid",
    },
    
  });
  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
  const DraggableDialog = (props) =>{
    const {
		imgmain,
		pins
  } = props;
  var adjsCount=0;
    const [adjPins, setAdjPins] = React.useState(pins);
    const [open, setOpen] = React.useState(false);
    
    if(pins.length !== adjPins.length){
      setAdjPins([...pins]);
      adjsCount=pins.length;
   }
   
    const handleTooltipClose = () => {
      setOpen(false);
    };
  
    const handleTooltipOpen = () => {
      setOpen(true);
    };
    const resetData = () => {
      setAdjPins([...[]])
    };
    const closeModal = () => {
      props.click.closeModal1();
    };
    const handleAddd = () => {
    props.click.handleModalAddPin(props.id);
    };
    const onImgLoad = pid => ({target:img}) => {
      adjustPosition(pid,img);
    }
    const adjustPosition = (pid,img)=>{
      adjsCount +=1;
      var pin = adjPins.find(x => x.id === pid);
      pin.pos_x -= (img.width/2);
      pin.pos_y -= (img.height);
      if(adjsCount === adjPins.length){
        setAdjPins([...adjPins]);
      }
    }  
     const classes = useStyles();
    return (
      <div>
        <Dialog
          open={props.show}
          onClose={props.close}
          fullWidth
          maxWidth="md"
          aria-labelledby="draggable-dialog-title"
        >
        {props.done?(
            <div>
            {props.checkImage?(
                <div>
                <DialogTitle id="draggable-dialog-title">{props.name}</DialogTitle>
                <Divider/>
                <DialogContent>
                <div className={classes.container}>
                <img className={classes.mainImage} src={window.$ImageBaseUrl+"/"+imgmain}/>
                {adjPins.map((pin) => {
                  return(
                      <HtmlTooltip interactive
                      title={
                        <React.Fragment>
                          <Typography color="inherit">{pin.description}</Typography>
                          <Button onClick={e => {props.click.handleDeleteItems(pin.id)}} class="btn btn-danger">Delete</Button>
                        </React.Fragment>
                      }
                    >
                <img  onLoad={onImgLoad(pin.id)} className={classes.pinImage} src={window.$ImageBaseUrl+"/"+pin.img_url} style={{top:pin.pos_y,left:pin.pos_x,width:20}} />
                </HtmlTooltip>
                  )
                })}
                </div>

                </DialogContent>
                </div>
            ):(
                <div>
                <DialogTitle id="draggable-dialog-title">{props.name}</DialogTitle>
            <Divider/>
            <DialogContent>
            <TableContainer>
            <Table aria-lable="simple table" className="table table-striped" style={{minWidth:650}}>
            <TableBody>
            <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{props.name}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>Description</TableCell>
            <TableCell>{props.desc}</TableCell>
            </TableRow>
            </TableBody>
            </Table>
            </TableContainer>
            </DialogContent>
                </div>)}
            </div>
        ):(
            <Bars/>
        )}
          <Divider/>
          <DialogActions>
          {props.checkImage?(
            <Button onClick={handleAddd} class="btn btn-primary">
            Add Pin
          </Button>
          ):(
            <div></div>
          )}
          
            <Button onClick={closeModal} class="btn btn-secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

export default DraggableDialog