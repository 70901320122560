import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '../components/Drawer';
import ModalProd from '../components/DialogWebDetail'
import ModalDelProd from '../components/DialogDeleteItem';

export default class StoryContainer extends Component {
	state = {
        show:false,showDelete:false,isDone:false,avaliable:true,
        contacts:[],modalItem:[],
        page:0,rowsPerPage:25,isLoading: true,
	  }
    handleChangePage = (event, newPage) => {
        this.setState({
            page:newPage
        })
    };
    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    };
    componentDidMount(){
      this.loadItem();
    }
    loadItem(){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({"t":"token"});
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(window.$webIndex, requestOptions)
        .then(response => response.json())
        .then(responseJson => {
          
          if(responseJson.result.length===0){
            this.setState({
                avaliable:true,
                isLoading:false
                });
        }else{
            this.setState({
                avaliable:false,
                contacts:responseJson.result,
                isLoading:false
            });
        }
        this.setState({
          isLoading:false
        });
        })
        .catch(error => console.log('error', error));
    }
    loadModal(id){
      var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    var raw = JSON.stringify({"t":"token","id":id});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch(window.$webFindById, requestOptions)
      .then(response => response.json())
      .then(result => {
        this.setState({
          modalItem:result.result,
          isDone:true
         
        })
        })
      .catch(error => console.log('error', error));
    }
    deleteItem(id){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({"t":"token","id":id});

      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$webDelete, requestOptions)
    .then(response => response.json())
    .then(result => {
      if(result.status===true){
        this.handleRefresh()
      }
    })
    .catch(error => console.log('error', error));
    }
    handleRefresh(){
      this.setState({
        brands:[],
        isLoading:true
      })
      this.loadItem();
    }
    handleModal(id){
      this.setState({
        show:true,
        isDone:false
      });
      this.loadModal(id);
    }
    closeModal=nr=>()=> {
      this.setState({
          show : false
      });
    }    
    handleModalDelte(id){
      this.setState({
        showDelete: true,
        show:false
      });
      this.loadModal(id);
    }
    closeModalDelete=nr=>()=> {
      this.setState({
        showDelete : false
      });
    }
    closeModalDeleteOk(id){
      this.setState({
        showDelete: false
      });
      this.deleteItem(id);
    }
	render() {
        const { contacts,page,rowsPerPage,isLoading,modalItem,isDone,avaliable} = this.state;
		return(
            <div>
            <Backdrop open={isLoading}>
            <CircularProgress color="inherit" />
            </Backdrop>
            <Drawer halaman={this.props.halaman} pindahHalaman={this.props.pindahHalaman}>
            <div>
            <h3>Inquiries</h3>
            </div>
            <Paper elevation={3}>
            {isLoading?(
              <div></div>
            ):(
              <div>
              {avaliable ? (
                <div>
                <div className="container d-flex justify-content-center">
                <h2>Item not Avaliable</h2>
                </div>
                <div className="container d-flex justify-content-center">
                <Button variant="contained" color="secondary" style={{marginTop:10,marginBottom:10}} onClick={()=>this.handleRefresh()}>
                Refresh
                </Button>
              </div>
              </div>
              ) :(
                <div>
                <div >
                <Button variant="contained" color="secondary" style={{marginTop:10,marginBottom:10,marginLeft:5}} onClick={()=>this.handleRefresh()}>
                Refresh
                </Button>
                </div>
                <TableContainer>
                <Table aria-label="simple table" className="table table-striped" style={{minWidth:650}}>
                <TableHead>
                <TableRow>
                <TableCell style={{fontWeight:'bold'}}>Action</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Name</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Email</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Subject</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Message</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Ip</TableCell>
                </TableRow>
                </TableHead>
               <TableBody>
               {contacts.slice(page*rowsPerPage,page*rowsPerPage+rowsPerPage)
                .map((items)=>(
                    <TableRow>
                    <TableCell><a href={"mailto:"+items.email}>Send mail</a></TableCell>
                    <TableCell><a href="#" class="text-primary" onClick={()=>this.handleModal(items.id)}>{items.name}</a></TableCell>
                    <TableCell>{items.email}</TableCell>
                    <TableCell>{items.subject}</TableCell>
                    <TableCell>{items.message}</TableCell>
                    <TableCell>{items.ip}</TableCell>
                    </TableRow>
                ))}
               </TableBody> 
                </Table>
                </TableContainer>
                <TablePagination
                component="div"
                count={contacts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />

                </div>
              )}
              </div>)}
            </Paper>
            { modalItem.map((item)=>(
              <ModalProd
              show={this.state.show}
              close={this.closeModal()}
              delete={()=>this.handleModalDelte(item.id)}
              done={isDone}
              name={item.name}
              id={item.id}
              createAt={item.created_at}
              updateAt={item.updated_at}
              email={item.email}
              subject={item.subject}
              message={item.message}
              ip={item.ip}
              />
            ))
            }
            { modalItem.map((item)=>(
              <ModalDelProd
              show={this.state.showDelete}
              close={this.closeModalDelete()}
              ok={()=>this.closeModalDeleteOk(item.id)}
              text={"message"}
              />
            ))
            }
            </Drawer>
		  </div>
		)
	}
}
