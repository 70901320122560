import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Bars from './BarLoading';
import './Modal.css';

class DraggableDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isOpen: false
        };
      }

  render() {
    return (
      <div>
        <Dialog
          open={this.props.show}
          onClose={this.props.close}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title">{this.props.name}</DialogTitle>
          <Divider/>
          {this.props.done?(
            <DialogContent>
            <TableContainer>
            <Table aria-label="simple table" className="table table-striped" style={{minWidth:650}}>
            <TableBody>
            <TableRow>
            <TableCell>{this.props.title1}</TableCell>
            <TableCell>{this.props.name}</TableCell>
            </TableRow>
            <TableRow>
            <TableCell>{this.props.title2}</TableCell>
            <TableCell>{this.props.desc}</TableCell>
            </TableRow>
            </TableBody>
            </Table>
        </TableContainer>
            </DialogContent>
          ):(
            <Bars/>
          )}
          <Divider/>
          <DialogActions>
          <Button onClick={this.props.edit} class="btn btn-primary">
            Edit
          </Button>
            <Button onClick={this.props.close} class="btn btn-secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DraggableDialog;