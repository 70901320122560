import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Bars from './BarLoading';
import './Modal.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  dense: {
    marginTop: 16,
  },
  menu: {
    width: 200,
  },
});

class UploadPhotoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            file:null,
            idText:this.props.idText,
            restUrl:this.props.restUrl,
            show:this.props.show,
            url:'',
            isAdd:true,
            imageUrl:'',
            desc:'',
            ids:'',
        };
      }

      handleFileChange = e => {
        const reader = new FileReader();
        var files=e.target.files[0];
        this.setState({
          file: e.target.files[0],
          url:e.target.files[0].name,
          imageUrl:URL.createObjectURL(e.target.files[0])
        })
        reader.onloadend = () => {
          this.setState({
            imageUrl: reader.result
          });
          
        }
      }
      handleDescChange = e => {
        this.setState({
          desc:e.target.value
        })
        
      }
      handleIdChange = e => {
        this.setState({
          ids:e.target.value
        })
        
      }
    
      uploadImage(){
        const{file,url,ids,desc,idText,restUrl}= this.state;

        this.setState({
          isAdd:false
        })

        var formdata = new FormData();
    formdata.append("t", "token");
    formdata.append(idText, ids);
    formdata.append("file[]", file, url);
    formdata.append("file_desc[]", desc)
    
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    
    fetch(restUrl, requestOptions)
      .then(response => response.json())
      .then(result =>
        {
          if(result.msg=="Success"){
            this.setState({
              isAdd:true,
              show:false
            })
          }else{
            this.setState({
              isAdd:true
            })
          }
          }
        )
      .catch(error => console.log('error', error));
      }

  render() {
    const { classes } = this.props;
    const {isAdd} = this.state;
    let imgPreview;
        if (this.state.imageUrl) {
            imgPreview = <img src={this.state.imageUrl} alt='' style={{height:80}}/>;
        }
    return (
      <div>
        <Dialog
          open={this.props.show}
          onClose={this.props.close}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle id="draggable-dialog-title">Are You Sure ?</DialogTitle>
          <Divider/>
          {isAdd ? (
            <DialogContent>
            <h5>Are you sure want to delete this {this.props.text} ? </h5>
            </DialogContent>
          ):(
            <Bars/>
          )}
          
          <Divider/>
          <DialogActions>
            <Button onClick={this.props.close} class="btn btn-secondary">
              Close
            </Button>
            <Button onClick={this.props.ok} class="btn btn-danger">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

UploadPhotoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(UploadPhotoDialog);