import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Mail from '@material-ui/icons/Mail';
import Web from '@material-ui/icons/Web';
import Group from '@material-ui/icons/Group';
import Product from '@material-ui/icons/Assignment';
import Event from '@material-ui/icons/Event';
import Story from '@material-ui/icons/Description';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Dashboard from '@material-ui/icons/Dashboard';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import Branding from '@material-ui/icons/BrandingWatermark';
import Itemss from '@material-ui/icons/Inbox';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import DialogChange from './DialogChangePass';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  grow: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9 + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    open: true,
    openSetting:false,
    openBrand:false,
    openProduct:false,
    anchorEl: false,
    sToken:'',
    show:false,
    newPass:'',
    oldPass:'',
    };
  }

  setNewPass = (e) => {
    this.setState({
        newPass: e.target.value
    })
  }
  setOldPass = (e) => {
    this.setState({
        oldPass: e.target.value
    })
  }
  handleModal(){
    this.setState({
      show:true
    });
  }
  closeModal=nr=>()=> {
    this.setState({
        show : false
    });
  }
  handleSubmit(){
    if(this.state.oldPass!==''){
      if(this.state.newPass!==''){
        this.setState({
          show:false
        })
        this.changePassword(this.state.sToken)
      }else{
        alert("Input new password")
      }
    }else{
      alert("Input old password")
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleOpenSettings=()=>{
    this.setState(state => ({ openSetting: !state.openSetting }));
 }

 handleOpenBrand=()=>{
    this.setState(state => ({ openBrand: !state.openBrand }));
 }

 handleOpenProduct=()=>{
    this.setState(state => ({ openProduct: !state.openProduct }));
 }

  handleDrawerClose = () => {
    this.setState({ open: false });
  };
  handleMenu() {
    this.setState({ anchorEl: true });
  };
  handleClose() {
    this.setState({ anchorEl: false });
  };
  handleLogout(){
    localStorage.removeItem('token');
    this.props.pindahHalaman('login');
  }

  checkActive(page){
  	if(page === this.props.halaman){
  		return true	;
  	}
  	return false;
  	
  }
  componentDidMount(){
    const token= localStorage.getItem('token');
		if(token===null){
			this.props.pindahHalaman('login');
		}else{
			this.setState({
        sToken:token
      })
		}
  }
  changePassword(token){
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({"token":token,"password":this.state.oldPass,"new_password":this.state.newPass});

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(window.$changePass, requestOptions)
      .then(response => response.json())
      .then(result => {
        if(result.status===true){

        }else{
          alert("Change password failed, please try again")
        }
      })
      .catch(error => console.log('error', error));
  }

  render() {
    const { classes, theme } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar disableGutters={!this.state.open} >
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, {
                [classes.hide]: this.state.open,
              })}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap className={classes.grow}>
              PT. Cipta Tapak Sejahtera
            </Typography>
                <IconButton
                  aria-haspopup="true"
                  onClick={()=>this.handleMenu()}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  getContentAnchorEl={null}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={this.state.anchorEl}
                  onClose={()=>this.handleClose()}
                >
                  <MenuItem onClick={()=>this.handleModal()}>Change Password</MenuItem>
                  <MenuItem onClick={()=>this.handleLogout()}>Logout</MenuItem>
                </Menu>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
        <ListItem button selected={this.checkActive('home')} onClick={()=>{this.props.pindahHalaman('home')}}>
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        
        <ListItem button onClick={this.handleOpenBrand}>
          <ListItemIcon>
            <Itemss />
          </ListItemIcon>
          <ListItemText primary="Items" />
          {this.state.openBrand ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openBrand} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} selected={this.checkActive('brands')} onClick={()=>{this.props.pindahHalaman('brands')}}>
            <ListItemIcon>
            <Branding />
          </ListItemIcon>
              <ListItemText primary="Brands" />
            </ListItem>
            <ListItem button className={classes.nested} selected={this.checkActive('product')} onClick={()=>{this.props.pindahHalaman('product')}}>
            <ListItemIcon >
            <Product />
            </ListItemIcon>
            <ListItemText primary="Products" />
          </ListItem>
          </List>
        </Collapse>
        <ListItem button onClick={this.handleOpenProduct}>
          <ListItemIcon>
            <Web />
          </ListItemIcon>
          <ListItemText primary="Web" />
          {this.state.openProduct ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.openProduct} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className={classes.nested} selected={this.checkActive('customer')} onClick={()=>{this.props.pindahHalaman('customer')}}>
            <ListItemIcon>
            <Group />
          </ListItemIcon>
              <ListItemText primary="Customers" />
            </ListItem>
            <ListItem button className={classes.nested} selected={this.checkActive('event')} onClick={()=>{this.props.pindahHalaman('event')}}>
            <ListItemIcon>
            <Event />
          </ListItemIcon>
            <ListItemText primary="Events" />
          </ListItem>
          <ListItem button className={classes.nested} selected={this.checkActive('story')} onClick={()=>{this.props.pindahHalaman('story')}}>
          <ListItemIcon>
          <Story />
        </ListItemIcon>
          <ListItemText primary="Stories" />
        </ListItem>
        <ListItem button className={classes.nested} selected={this.checkActive('content')} onClick={()=>{this.props.pindahHalaman('content')}}>
        <ListItemIcon>
        <WebAssetIcon />
        </ListItemIcon>
          <ListItemText primary="Web Content" />
        </ListItem>
        <ListItem button className={classes.nested} selected={this.checkActive('picmap')} onClick={()=>{this.props.pindahHalaman('picmap')}}>
        <ListItemIcon>
        <WebAssetIcon />
        </ListItemIcon>
          <ListItemText primary="PicMap" />
        </ListItem>
          </List>
        </Collapse>
        <ListItem button selected={this.checkActive('contact')} onClick={()=>{this.props.pindahHalaman('contact')}}>
        <ListItemIcon>
          <Mail />
        </ListItemIcon>
        <ListItemText primary="Contacts" />
      </ListItem>
      </List>
          <Divider />
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
         {this.props.children}
        </main>
        <DialogChange
        show={this.state.show}
        close={this.closeModal()}
        oldPass={(e)=>this.setOldPass(e)}
        newPass={(e)=>this.setNewPass(e)}
        submit={()=>this.handleSubmit()}
        />
      </div>
    );
  }
}

MiniDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(MiniDrawer);