import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '../components/Drawer';
import ModalProd from '../components/DialogDetailPicmap';
import ModalAddImg from '../components/DialogUploadImage';
import ModalAdd from '../components/DialogAddItem';
import ModalAddPin from '../components/DialogAddPicMap';
import ModalDelPin from '../components/DialogDeleteItem';

export default class PicMapContainer extends Component {
    state={
        page:0,rowsPerPage:25,isLoading: true,avaliable:true,isDone:false,showDeletePin:false,
        showAdd:false,showAddImg:false,showAddPin:false,showEdit:false,showDelete:false,show:false,
        name:'',desc:'',ids:'',imageUrl:'',url:'',file:null,images:'',posx:'',posy:'',urlPin:'',descPin:'',idDelete:'',
        picmaps:[],pins:[],modalItem:[],listImg:[]
    }
    handleChangePage = (event, newPage) => {
        this.setState({
            page:newPage
        })
    };
    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
      };
    setPosx=(e)=>{
        this.setState({
            posx: e.target.value
        })
    }
    setPosy=(e)=>{
        this.setState({
            posy: e.target.value
        })
    }
    setUrlPin=(e)=>{
        this.setState({
            urlPin: e.target.value
        })
    }
    setDescPin=(e)=>{
        this.setState({
            descPin: e.target.value
        })
    }
    setName = (e) => {
        this.setState({
            name: e.target.value
        })
    }
    setId = (e) => {
        this.setState({
            ids: e.target.value
        })
    }
    setDesc = (e) => {
        this.setState({
            desc: e.target.value
        })
    }
    handleRefresh(){
        this.setState({
            picmaps:[],
            isLoading:true
        })
        this.loadItem();
    }
    componentDidMount(){
        this.loadItem();
        this.loadListImage();
    }
    loadListImage(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({"t":"token"});

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(window.$picmapListImage, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
                listImg:result.result
            })
        })
        .catch(error => console.log('error', error));
    }
    loadItem(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({"t":"token"});
        
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        
        fetch(window.$picmapIndex, requestOptions)
        .then(response => response.json())
        .then(responseJson => {
            if(responseJson.result.length===0){
            this.setState({
                avaliable:true,
                isLoading:false
                });
        }else{
            this.setState({
                avaliable:false,
                picmaps:responseJson.result,
                isLoading:false
            });
        }
        this.setState({
            isLoading:false
        });
        })
        .catch(error => console.log('error', error));
    }
    loadModal(id,shwoDetail=false){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({"t":"token","id":id});
    
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
    
    fetch(window.$picmapFindById, requestOptions)
        .then(response => response.json())
        .then(result => {
            const newData=result.result[0].items.map(a=>({...a}))
            this.setState({
                ids:result.result[0].id,
                pins:newData,
                modalItem:result.result,
                isDone:true,
                show:shwoDetail
            })
            
        })
        .catch(error => console.log('error', error));
    }
    addItem(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        var raw = JSON.stringify({"t":"token","name":this.state.name,"description":this.state.desc});
    
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        fetch(window.$picmapAdd, requestOptions)
        .then(response => response.json())
        .then(result =>
        {
            if(result.msg==="Success"){
            this.setState({
            name:'',desc:'',ids:'',
            })
            this.handleRefresh()
            }else{
            this.setState({
            name:'',desc:'',ids:'',
            })
            }
        }
        )
        .catch(error => console.log('error', error));
    }
    uploadImage(){
        const{file,url,ids}= this.state;

        this.setState({
            isAdd:false
        })
        var formdata = new FormData();
        formdata.append("t", "token");
        formdata.append("eid", ids);
        formdata.append("file", file, url);
    
    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };
    
    fetch(window.$picmapUploadImg, requestOptions)
    .then(response => response.json())
    .then(result =>
        {
            if(result.msg==="Success"){
            this.setState({
                url:'', imageUrl:'', desc:'', ids:'',file:null,
                isLoading:false
            })
            this.handleRefresh()
            }else{
            this.setState({
                isLoading:false
            })
            }
        }
        )
    .catch(error => console.log('error', error));
    }
    addPin(){
        const{posx,posy,urlPin,descPin,ids}= this.state;
        const posxx=Math.round(posx);
        const posyy=Math.round(posy);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({"t":"token","eid":ids,"img_url":urlPin,"pos_x":posxx,"pos_y":posyy,"description":descPin});

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        fetch(window.$picmapAddItem, requestOptions)
        .then(response => response.json())
        .then(result => {
            
            if(result.msg==="Success"){
                this.loadModal(ids,true);
                this.setState({
                    url:'', imageUrl:'', desc:'', ids:'',file:null,posx:'',posy:'',urlPin:'',descPin:'',
                    isLoading:false
                })
                this.handleRefresh()
                }else{
                this.setState({
                    isLoading:false
                })
                }
            
        })
        .catch(error => console.log('error', error));
    }
    deletePin(id){
        this.setState({
        isLoading:true
        })
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({"t":"token","id":id});

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(window.$picmapDeleteItem, requestOptions)
    .then(response => response.json())
    .then(result => {
        if(result.status===true){
            this.loadModal(this.state.ids,true);
        this.setState({
            isLoading:false,
            idDelete:''
        })
        }else{
        this.setState({
            isLoading:false,
            idDelete:''
        })
        }
    })
    .catch(error => console.log('error', error));
    }
    handleFileChange = e => {
        const reader = new FileReader();
        this.setState({
            file: e.target.files[0],
            url:e.target.files[0].name,
            imageUrl:URL.createObjectURL(e.target.files[0])
        })
        reader.onloadend = () => {
            this.setState({
            imageUrl: reader.result
            });

        }
    }
    handleModal(id){
        this.setState({
            show:true,
            isDone:false
        });
        this.loadModal(id,true);
    }
    closeModal=nr=>()=> {
        this.setState({
            show : false,
            modalItem:[]
        });
    }
    closeModal1() {
        this.setState({
            show : false,
            modalItem:[]
        });
    }
    handleModalAdd(){
        this.setState({
        showAdd: true,
        });
    }
    closeModalAdd=nr=>()=> {
        this.setState({
            showAdd : false,
            modalItem:[]
        });
    }
    hadleSubmit=nr=>()=> {
        if(this.state.name!==''){
            if(this.state.desc!==''){
            this.setState({
                showAdd : false
            });
            this.addItem();
            }else{
            alert('please input description')
            }
        }else{
            alert('please input name')
        }
        
    }
    handleModalUpload(id){
        this.setState({
            showAddImg: true,
            showImage:false
        });
        this.loadModal(id);
    }
    closeModalAddImg=nr=>()=> {
        this.setState({
            showAddImg : false
        });
    }    
    hadleSubmitImg=nr=>()=> {
        if(this.state.file!== null){
                this.setState({
                showAddImg : false,
                isLoading:true
                });
                this.uploadImage();
        }else{
            alert('please input select image')
        }
    }
    handleModalAddPin(id){
        this.setState({
            showAddPin: true,
            show:false
        });
        this.loadModal(id);
    }
    posx(number){
        this.setState({
            posx:number
        });
    }
    posy(number){
        this.setState({
            posy:number
        });
    }
    closeModalAddPin=nr=>()=> {
        this.setState({
            showAddPin : false
        });
    }
    closeAddPin1() {
        this.setState({
            showAddPin : false
        });
    }
    hadleSubmitPin1(){
        if(this.state.posx!== ''){
            if(this.state.urlPin!==''){
                if(this.state.descPin!==''){
                    this.setState({
                        showAddPin : false,
                        isLoading:true
                        });
                        this.addPin();
                }else{
                    alert('please input description')
                }
                
            }else{
                alert('please select pin image')
            }
        }else{
            alert('please pin location')
        }
    }    
    hadleSubmitPin=nr=>()=> {
        if(this.state.posx!== ''){
            if(this.state.urlPin!==''){
                if(this.state.descPin!==''){
                    this.setState({
                        showAddPin : false,
                        modalItem:[],
                        isLoading:true
                        });
                        this.addPin();
                }else{
                    alert('please input description')
                }
                
            }else{
                alert('please select pin image')
            }
        }else{
            alert('please pin location')
        }
    }
    handleDeleteItems(id){
        this.setState({
            idDelete:id,
            show:false,
            showDeletePin:true
          })
    }
    handleDeletePin(id){
        this.setState({
          idDelete:id,
          show:false,
          showDeletePin:true
        })
      }
      closeModalDeletePinOk(){
        this.setState({
          showDeletePin: false
        });
        this.deletePin(this.state.idDelete);
      }
      
      closeModalDeletePin=nr=>()=> {
        this.setState({
          showDeletePin : false,
          idDelete:''
        });
      }
      loadImageItem(images){
        if(images!=null){
          var urls=images.split('/')
          var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+"S"+"/"+urls[3]
          return img
        }
        return require('../assets/placeholder.jpg');
      }
    checkImage(images){
        if(images!==""){
            return true;
        }
        return false;
    }
render(){
    const { picmaps,page,rowsPerPage,isLoading,modalItem,isDone,avaliable} = this.state;
    return(
        <div>
        <Backdrop open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
        <Drawer halaman={this.props.halaman} pindahHalaman={this.props.pindahHalaman}>
        <div>
        <h3>PicMap</h3>
        </div>
        <Paper elevation={3}>
        {isLoading?(
          <div></div>
        ):(
          <div>
          {avaliable ? (
            <div>
            <div className="container d-flex justify-content-center">
            <h2>Item not Avaliable</h2>
            </div>
            <div className="container d-flex justify-content-center">
            <Button variant="contained" color="secondary" style={{marginTop:10,marginBottom:10}} onClick={()=>this.handleRefresh()}>
            Refresh
            </Button>
          </div>
          </div>
          ) :(
            <div>
            <div >
            <Button variant="contained" color="secondary" style={{marginTop:10,marginBottom:10}} onClick={()=>this.handleRefresh()}>
            Refresh
            </Button>
            </div>
            <TableContainer>
            <Table aria-label="simple table" className="table table-striped" style={{minWidth:650}}>
            <TableHead>
            <TableRow>
            <TableCell style={{fontWeight:'bold'}}>Name</TableCell>
            <TableCell style={{fontWeight:'bold'}}>Image</TableCell>
            <TableCell style={{fontWeight:'bold'}}>Description</TableCell>
            </TableRow>
            </TableHead>
           <TableBody>
           {picmaps.slice(page*rowsPerPage,page*rowsPerPage+rowsPerPage)
            .map((items)=>(
                <TableRow>
                <TableCell><a href="#" class="text-primary" onClick={()=>this.handleModal(items.id)}>{items.name}</a></TableCell>
                <TableCell>{this.checkImage(items.img_url)?(<a href="#" onClick={()=>this.handleModal(items.id)} class="text-primary"><img src={this.loadImageItem(items.img_url)} style={{height:50,maxWidth:100}} alt="img" onError={(e)=>e.target.src=window.$ImageBaseUrl+"/"+items.img_url}/></a>):(<div><Button onClick={()=>this.handleModalUpload(items.id)} class="btn btn-primary">Upload Image</Button></div>)}</TableCell>
                <TableCell>{items.description}</TableCell>
                </TableRow>
            ))}
           </TableBody> 
            </Table>
            </TableContainer>
            <TablePagination
            component="div"
            count={picmaps.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />

            </div>
          )}
          </div>)}
        </Paper>
        </Drawer>
        <ModalAdd
        show={this.state.showAdd}
        close={this.closeModalAdd()}
        label1={"Name"}
        label2={"Description"}
        title={"Add PicMap"}
        name={e=>this.setName(e)}
        desc={e=>this.setDesc(e)}
        submit={this.hadleSubmit()}
        />
        <ModalAddImg
            show={this.state.showAddImg}
            close={this.closeModalAddImg()}
            title={"Upload Image"}
            imageUrl={this.state.imageUrl}
            fileChange={(e)=>this.handleFileChange(e)}
            submit={this.hadleSubmitImg()}
        />
        {modalItem.map((item)=>(
        <ModalProd
        show={this.state.show}
        close={this.closeModal()}
        click={this}
        click1={this}
        done={isDone}
        name={item.name}
        id={item.id}
        desc={item.description}
        pins={this.state.pins}
        imgmain={item.img_url}
        checkImage={this.checkImage(item.img_url)}
        addPin={()=>this.handleModalAddPin(item.id)}
        upload={()=>this.handleModalUpload(item.id)}
        />
        ))}
        {modalItem.map((item)=>(
            <ModalAddPin
            show={this.state.showAddPin}
            close={this.closeModalAddPin()}
            close2={this}
            posx={(e)=>this.setPosx(e)}
            posy={(e)=>this.setPosy(e)}
            posx1={this}
            posy1={this}
            desc={(e)=>this.setDescPin(e)}
            imgUrl={(e)=>this.setUrlPin(e)}
            imgmain={item.img_url}
            listImgUrl={this.state.listImg}
            submit={this.hadleSubmitPin()}
            />
            ))}
        <ModalDelPin
        show={this.state.showDeletePin}
        close={this.closeModalDeletePin()}
        ok={()=>this.closeModalDeletePinOk()}
        text={"pin"}
        />
        </div>
    )
}
}