import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Drawer from '../components/Drawer';
import ModalAdd from '../components/DialogAddItem';
import ModalProd from '../components/DialogDetailItem';
import ModalDelProd from '../components/DialogDeleteItem';
import ModalEditProd from '../components/DialogEditItem'
import ModalAddLogo from '../components/DialogUploadImage';

export default class CustomerContainer extends Component {
    state={
        show:false,showEdit:false,showDelete: false,showAddImg: false,isLoading:true,isDone:false,avaliable:true,showAdd:false,
        customers:[],modalItem:[],name:'',desc:'',ids:'',logoUrl:'',imageUrl:'',url:'',file:null,idDelete:'',
        page:0,rowsPerPage:25,
    }
    handleChangePage = (event, newPage) => {
        this.setState({
            page:newPage
        })
    };
    handleChangeRowsPerPage = event => {
      this.setState({ rowsPerPage: event.target.value });
    };
    setName = (e) => {
        this.setState({
            name: e.target.value
        })
      }
      setId = (e) => {
        this.setState({
            ids: e.target.value
        })
      }
      setDesc = (e) => {
        this.setState({
            desc: e.target.value
        })
      }
    componentDidMount(){
    this.loadItem()
    }
    loadItem(){
        const{brandIndex}=this.state
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({"t":"token"});
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        fetch(window.$customerIndex, requestOptions)
          .then(response => response.json())
          .then(responseJson => {
            if(responseJson.result.length==0){
              this.setState({
                  avaliable:true,
                  isLoading:false
                  });
          }else{
              this.setState({
                  avaliable:false,
                  customers:responseJson.result,
                  isLoading:false
              });
          }
          this.setState({
            isLoading:false
          });
          })
          .catch(error => console.log('error', error));
    }
    addItem(){
        var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({"t":"token","name":this.state.name,"description":this.state.desc});

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
    };

    fetch(window.$customerAdd, requestOptions)
    .then(response => response.json())
    .then(result =>
    {
        if(result.msg=="Success"){
        this.setState({
            name:'',desc:'',ids:'',
        })
        this.handleRefresh()
        }else{
        this.setState({
            name:'',desc:'',ids:'',
        })
        }
    }
    )
    .catch(error => console.log('error', error));
    }
    loadModal(id){
        var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({"t":"token","id":id});
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(window.$customerFindById, requestOptions)
        .then(response => response.json())
        .then(result => {
          this.setState({
            ids:result.result[0].id,
            name:result.result[0].name,
            desc:result.result[0].description,
            logoUrl:result.result[0].logo_url,
            modalItem:result.result,
            isDone:true
           
          })
          })
        .catch(error => console.log('error', error));
      }
    deleteItem(id){
        const{productDelete}=this.state;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        var raw = JSON.stringify({"t":"token","id":id});
  
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
  
    fetch(window.$customerDelete, requestOptions)
    .then(response => response.json())
    .then(result => {
        if(result.status==true){
          this.handleRefresh()
        }
    })
    .catch(error => console.log('error', error));
    }
    editItem(id){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
  
        var raw = JSON.stringify({"t":"token","id":id,"Name":this.state.name,"Description":this.state.desc});
        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
  
        fetch(window.$customerUpdate, requestOptions)
        .then(response => response.json())
        .then(result =>
        {
        if(result.msg=="Success"){
          this.setState({
            name:'',desc:'',ids:'',
          })
          this.handleRefresh()
        }else{
          this.setState({
            name:'',desc:'',ids:'',
          })
        }
        }
        )
        .catch(error => console.log('error', error));
    }
    uploadImage(){
    const{file,url,ids,descImg,idText,restUrl}= this.state;
      
  
    var formdata = new FormData();
    formdata.append("t", "token");
    formdata.append("eid", ids);
    formdata.append("file", file, url);
    
    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
    };
    
    fetch(window.$customerUpload, requestOptions)
    .then(response => response.json())
    .then(result =>
        {
          if(result.msg=="Success"){
            this.setState({
              url:'', imageUrl:'', desc:'', ids:'',file:null,
              isLoading:false
            })
            this.handleRefresh()
          }else{
            this.setState({
              isLoading:false
            })
          }
        }
        )
    .catch(error => console.log('error', error));
    }
    handleRefresh(){
        this.setState({
            customers:[],
            isLoading:true
        })
        this.loadItem();
    }
    handleModalAdd(){
        this.setState({
            showAdd: true,
        });
    }
    closeModalAdd=nr=>()=> {
        this.setState({
            showAdd : false
        });
    }
    handleModal(id){
        this.setState({
            show:true,
            isDone:false
        });
        this.loadModal(id);
    }
    closeModal=nr=>()=> {
        this.setState({
            show : false
        });
    }
    hadleSubmit=nr=>()=> {
        if(this.state.name!=''){
        if(this.state.desc!=''){
            this.setState({
            showAdd : false
        });
        this.addItem();
        }else{
            alert('please input description')
        }
        }else{
        alert('please input name')
        }
        
    }
    handleModalEdit(id){
        this.setState({
          showEdit: true,
          isDone:false,
          show:false
        });
        this.loadModal(id);
    }
    closeModalEdit=nr=>()=> {
        this.setState({
          showEdit : false
        });
      }
    handleSubmitEdit(id){
        if(this.state.name!=''){
          if(this.state.desc!=''){
            this.setState({
              showEdit: false
            });
            this.editItem(id);
          }else{
            alert('please input description')
          }
        }else{
          alert('please input name')
        }
        
    }
    handleModalDelte(id){
        this.setState({
          showDelete: true,
          show:false
        });
        this.loadModal(id);
    }
    closeModalDelete=nr=>()=> {
        this.setState({
          showDelete : false
        });
      }
      closeModalDeleteOk(id){
        this.setState({
          showDelete: false
        });
        this.deleteItem(id);
      }
    handleModalUpload(id){
        this.setState({
          showAddImg: true,
          show:false
        });
        this.loadModal(id);
    }
    closeModalAddImg=nr=>()=> {
        this.setState({
            showAddImg : false
        });
    }
    hadleSubmitImg=nr=>()=> {
        if(this.state.file!= null){
          
            this.setState({
              showAddImg : false,
              isLoading:true
          });
          this.uploadImage();
        }else{
          alert('please input select image')
        }
    }
    handleFileChange = e => {
        const reader = new FileReader();
        var files=e.target.files[0];
        this.setState({
          file: e.target.files[0],
          url:e.target.files[0].name,
          imageUrl:URL.createObjectURL(e.target.files[0])
        })
        reader.onloadend = () => {
          this.setState({
            imageUrl: reader.result
          });
          
        }
      }
    loadImageItem(images){
        if(images!=null){
          var urls=images.split('/')
          var img=window.$ImageBaseUrl+"/"+urls[0]+"/"+urls[1]+"/"+urls[2]+"/"+"S"+"/"+urls[3]
          return img
        }
        return require('../assets/placeholder.jpg');
      }
    checkLogo(Image){
        if(Image!=""){
            return true
        }
        return false
    }
    render(){
        const { customers,isLoading,avaliable,page,rowsPerPage,modalItem,isDone} = this.state;
        return(
        <div>
        <Backdrop open={isLoading}>
        <CircularProgress color="inherit" />
        </Backdrop>
        <Drawer halaman={this.props.halaman} pindahHalaman={this.props.pindahHalaman}>
        <div>
        <div>
        <h3>Customers</h3>
        </div>
        <Paper elevation={3}>
        {isLoading?(
            <div></div>
        ):(
            <div>
            {avaliable?(
                <div>
                <div className="container d-flex justify-content-center">
                <h2>Item not Avaliable</h2>
                </div>
                <div className="container d-flex justify-content-center">
                <Button variant="contained" color="primary"style={{marginTop:10,marginBottom:10,marginLeft:10}} onClick={()=>this.handleModalAdd()}>
                Add
                </Button>
                </div>
                </div>
            ):(
                <div>
                <div >
                <Button variant="contained" color="primary"style={{marginTop:10,marginBottom:10,marginLeft:10}} onClick={()=>this.handleModalAdd()}>
                Add
                </Button>
                <Button variant="contained" color="secondary" style={{marginTop:10,marginBottom:10}} onClick={()=>this.handleRefresh()}>
                Refresh
                </Button>
                </div>
                <TableContainer>
                <Table aria-label="simple table" className="table table-striped" style={{minWidth:650}}>
                <TableHead>
                <TableRow>
                <TableCell style={{fontWeight:'bold'}}>Name</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Logo</TableCell>
                <TableCell style={{fontWeight:'bold'}}>Description</TableCell>
                </TableRow>
                </TableHead>
               <TableBody>
               {customers.slice(page*rowsPerPage,page*rowsPerPage+rowsPerPage)
                .map((items)=>(
                    <TableRow>
                    <TableCell><a href="#" class="text-primary" onClick={()=>this.handleModal(items.id)}>{items.name}</a></TableCell>
                    <TableCell>{this.checkLogo(items.logo_url)? (<a href="#" onClick={()=>this.handleModalUpload(items.id)}><img src={this.loadImageItem(items.logo_url)} style={{height:50,maxWidth:100}} alt="img" onError={(e)=>e.target.src=window.$ImageBaseUrl+"/"+items.logo_url}/></a>):(<div><Button onClick={()=>this.handleModalUpload(items.id)} class="btn btn-primary">Upload</Button></div>)}</TableCell>
                    <TableCell>{items.description}</TableCell>
                    </TableRow>
                ))}
               </TableBody> 
                </Table>
                </TableContainer>
                <TablePagination
                component="div"
                count={customers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                </div>)}
            </div>)}
        </Paper>
        </div>
        </Drawer>
        <ModalAdd
        show={this.state.showAdd}
        close={this.closeModalAdd()}
        label1={"Customer Name"}
        label2={"Description"}
        title={"Add Customer"}
        name={e=>this.setName(e)}
        desc={e=>this.setDesc(e)}
        submit={this.hadleSubmit()}
        />
        {modalItem.map((item)=>(
            <ModalProd
            show={this.state.show}
            close={this.closeModal()}
            edit={()=>this.handleModalEdit(item.id)}
            delete={()=>this.handleModalDelte(item.id)}
            upload={()=>this.handleModalUpload(item.id)}
            done={isDone}
            title1={"Name"}
            title2={"Description"}
            name={item.name}
            id={item.id}
            desc={item.description}
            checkLogo={this.checkLogo(item.logo_url)}
            />
        ))}
        { modalItem.map((item)=>(
            <ModalDelProd
            show={this.state.showDelete}
            close={this.closeModalDelete()}
            ok={()=>this.closeModalDeleteOk(item.id)}
            text={"customer"}
            />
        ))
        }
        <ModalEditProd
        show={this.state.showEdit}
        close={this.closeModalEdit()}
        done={isDone}
        title={"Edit Customer"}
        name={this.state.name}
        id={this.state.ids}
        desc={this.state.desc}
        nameChange={e=>this.setName(e)}
        descChange={e=>this.setDesc(e)}
        submit={()=>this.handleSubmitEdit(this.state.ids)}
        />
        <ModalAddLogo
            show={this.state.showAddImg}
            close={this.closeModalAddImg()}
            title={"Upload Logo"}
            imageUrl={this.state.imageUrl}
            fileChange={(e)=>this.handleFileChange(e)}
            descChange={e=>this.setDescImg(e)}
            submit={this.hadleSubmitImg()}
            />
            
        </div>
        );
    }
}