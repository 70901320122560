import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import './Modal.css';

const useStyles = makeStyles({
    container : {
        position: "relative",
        top: 0,
        left: 0,
        flex:1,
    },
    mainImage: {
         position: "relative",
         minWidth:'100%'
         //top: 0,
         //left: 0,
         //border: "1px red solid"
    },
    pinImage: {
            position: "absolute",
            //border: "1px green solid",
    },
      formControl2: {
        minWidth: 120,
      },
      dense: {
        marginTop: 16,
      },
      menu: {
        width: 200,
      },
  });

  const DraggableDialog = (props) =>{
    const {
		imgmain,
		pins
	} = props;
    const [adjPins, setAdjPins] = React.useState(pins);
    const [clickPos, setClickPos] = React.useState({top:0,left:0});
	const [clickPosText, setClickPosText] = React.useState(null);
	const [top, setTop] = React.useState("");
    const [left, setLeft] = React.useState("");
    const [age, setAge] = React.useState('');
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
        setOpen(false);
    };
    
    const handleOpen = () => {
        setOpen(true);
    };
    const handleCloseModal = () => {
      setClickPosText("");
      props.close2.closeAddPin1();
    };
    const handleSubmit = () => {
      setClickPosText("");
    props.close2.hadleSubmitPin1();
    };
	const onClickEvent = (event) =>  {
	  let currentTargetRect = event.currentTarget.getBoundingClientRect();
	  // const event_offsetX = event.pageX - currentTargetRect.left,
	  //       event_offsetY = event.pageY - currentTargetRect.top;

	  const event_offsetX = event.pageX - window.pageXOffset - currentTargetRect.left,
	        event_offsetY = event.pageY - window.pageYOffset - currentTargetRect.top;
	       
	  const cPos={
	  	top:event_offsetY,
	  	left:event_offsetX,
	  };
	  const cPosText = event_offsetX+","+event_offsetY;
	  setClickPos({...cPos});
      setClickPosText(cPosText);
      props.posx1.posx(event_offsetX);
      props.posy1.posy(event_offsetY);
	}
    const onImgLoad = pid => ({target:img_url}) => {
        adjPins.map( (pin,idx) => {
                const img=window.$ImageBaseUrl+"/"+img_url
                if(pin.id==pid){
                    pin.pos_x = pin.pos_x-(img.naturalWidth/2);
                    pin.pos_y = pin.pos_y-(img.naturalHeight/1.1);
                }
        });
        setAdjPins([...adjPins]);
 
     }
     const classes = useStyles();
    return (
      <div>
        <Dialog
          open={props.show}
          onClose={props.close}
          fullWidth
          maxWidth="md"
          aria-labelledby="draggable-dialog-title"
        >
            <div>
                <DialogTitle id="draggable-dialog-title">Add Pin</DialogTitle>
                <Divider/>
                <DialogContent>
                <div className={classes.container}>
                <img className={classes.mainImage} src={window.$ImageBaseUrl+"/"+imgmain} onClick={onClickEvent}/>
                <span className={classes.pinImage} style={clickPos}>{clickPosText}</span>
                </div>
                <FormControl variant="outlined" fullWidth className={classes.textField} margin="normal">
                <InputLabel  id="demo-controlled-open-select-label">Pin Image</InputLabel>
                <Select 
                  labelId="demo-controlled-open-select-label"
                  open={open} onClose={handleClose}
                  onOpen={handleOpen}
                  onChange={props.imgUrl}
                  input={
                    <OutlinedInput
                      name="Pin Image"
                      labelWidth={70}
                      id="demo-controlled-open-select-label"
                    />
                  }
                >
                {props.listImgUrl.map(brand => (
                  <MenuItem value={brand}>
                  <ListItemIcon>
                    <img  src={window.$ImageBaseUrl+"/"+brand} width="40px"/>
                  </ListItemIcon>
                  </MenuItem>
                ))}
                </Select>
            </FormControl>
                <TextField label="Description"  type="text" fullWidth margin="normal" variant="outlined" onChange = {props.desc}/>
                </DialogContent>
            </div>
          <Divider/>
          <DialogActions>
            <Button onClick={handleCloseModal} class="btn btn-secondary">
              Close
            </Button>
            <Button onClick={handleSubmit} class="btn btn-danger">
            Submit
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

export default DraggableDialog